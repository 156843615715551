import React, {ComponentType, useEffect, useState} from "react";
import {connect} from "react-redux";
import {AppStateType} from "../../redux/store";
import {
    clearCurrentCvAction,
    clearSuccesses,
    deleteCv,
    postCvForm,
    setCurrentCv
} from "../../redux/cv/actions";
import {NewCvType} from "../../common/types";
import {Redirect, withRouter, Prompt} from "react-router-dom";
import {
    selectorCurrentCv,
    selectorIsNewCv,
    selectorResponseSuccessDelete,
    selectorResponseSuccessPost,
    selectorIsLoading, selectorIsSaving
} from "../../redux/cv/selectors";

import {useIdleTimer} from 'react-idle-timer'
import {isEmpty, saveForm} from "../../common/formCvConstants";
import {CvForm} from "./CvForm";
import {compose} from "redux";
import {Preloader} from "../../assets/Preloader/Preloader";
import styles from "./CvForm.module.scss";
import {HeaderEdit} from "../Headers/EditHeader";
import {selectorIsLogin} from "../../redux/login/selectors";


const CvFormC = ({
                     currentCv,
                     postCvForm,
                     deleteCv,
                     isSuccessDelete,
                     isSuccessPost,
                     isLoading,
                     clearSuccesses,
                     setCurrentCv,
                     isSaving,
                     isLogin,
                     match,
                 }: NewCvType): JSX.Element => {

    const [isBack, setIsBack] = useState(false)
    const [isHandleSaved, setIsHandleSaved] = useState(false)
    let token = localStorage.getItem(`token`)


    const handleOnIdle = () => {
        saveForm()
    }
    const handleOnActive = () => {
    }

    useIdleTimer({
        timeout: 3000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 200,
        events: ['keydown', 'mousemove']
    })

    useEffect(() => {
        if (isBack) return saveForm()
    }, [isBack])

    if (Object.keys(currentCv).length === 0 && !isLoading) {
        setCurrentCv(match.params.cvId)
    }

    const onSubmitEditedForm = async (values: any) => {

        let tempTitle = values.general.name

        if (values.general.name.length === 0) {
            tempTitle = 'Empty title'
        }
        if (!isEmpty(values) && !isBack) {
            currentCv = {...currentCv, document: values, title: tempTitle}
            postCvForm(currentCv, currentCv.pk)
        } else if (isBack && isEmpty(values) && !isSuccessDelete) {
            clearSuccesses()
            deleteCv(currentCv.pk)
            setIsHandleSaved(true)
        } else if (!isEmpty(values) && isBack) {
            currentCv = {...currentCv, document: values, title: tempTitle}
            postCvForm(currentCv, currentCv.pk)
            setIsHandleSaved(true)
        }
    };

    if (isSuccessPost && isBack && isHandleSaved && !isSaving) {
        return <Redirect to={`/cv/${currentCv.pk}`}/>
    }

    if (isSuccessDelete && isBack && isHandleSaved && !isSaving) {
        return <Redirect to={'/cv-list'}/>
    }

    if (!isLogin && (token === '')) {
        return <Redirect to={'/login/'}/>
    }

    return (
        <div className={styles.flexContainer}>
            <HeaderEdit setIsBack={setIsBack}/>
            <section>
                {
                    isLoading ?
                        <Preloader/> :
                        <div>
                            <Prompt
                                message={(location, action) => {
                                    if (action === 'POP') {
                                        setIsBack(true)
                                    }
                                    return location.pathname.startsWith("/edit-cv")
                                }}
                            />
                            <CvForm initialValue={currentCv}
                                    onSubmitEditedForm={onSubmitEditedForm}
                            />
                        </div>}
            </section>
        </div>

    )
}

export const CvFormContainer = compose<ComponentType>(
    withRouter, connect(
        (state: AppStateType) => ({
            currentCv: selectorCurrentCv(state),
            isLoading: selectorIsLoading(state),
            isNewCv: selectorIsNewCv(state),
            isSuccessPost: selectorResponseSuccessPost(state),
            isSuccessDelete: selectorResponseSuccessDelete(state),
            isSaving: selectorIsSaving(state),
            isLogin: selectorIsLogin(state),
        }), {
            postCvForm,
            clearCurrentCvAction,
            deleteCv,
            clearSuccesses,
            setCurrentCv
        })
)(CvFormC)
