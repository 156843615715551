import React from "react";
import styles from "./Header.module.scss";
import loader from "../../../assets/images/treeDotsLoader.svg";
import {connect} from "react-redux";
import {AppStateType} from "../../../redux/store";
import {selectorIsSaving} from "../../../redux/cv/selectors";
import {ArrowSmLeftIcon} from "@heroicons/react/outline";
import {saveForm} from "../../../common/formCvConstants";
import {UserInHeader} from "../UserInHeader";

const HeaderEditC = ({isSaving, setIsBack}:
                         { isSaving: boolean, setIsBack: (value: boolean) => void }): JSX.Element => {

    const goBack = async () =>  {
        await setIsBack(true)
        saveForm()
    }

    return (
        <header className={styles.headerContainer}>
            <button onClick={() => goBack()}
                    disabled={isSaving}
                    className={styles.buttonBack}>
                <ArrowSmLeftIcon className={styles.smallIcon}/>
            </button>
            <div className={styles.filter}>
                <div>
                    {isSaving ? <div>
                            <img src={loader} alt="save" className={styles.smallIcon}/>
                            <span>saving</span>
                        </div> :
                        <span className={styles.saved}>saved</span>}
                </div>
                <UserInHeader/>
            </div>

        </header>
    )
}

export const HeaderEdit = connect(
    (state: AppStateType) => ({
        isSaving: selectorIsSaving(state),
    }), {})
(HeaderEditC)