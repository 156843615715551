import React from "react";
import styles from "./LogoutButton.module.scss"
import {GoogleLogout} from "react-google-login";
import {connect} from "react-redux";
import {AppStateType} from "../../redux/store";
import {logOutGoogle} from "../../redux/login/actions";

const LogoutButtonC = ({logOutGoogle}: {logOutGoogle: ()=>void}) => {

    return (
        <GoogleLogout
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            onLogoutSuccess={logOutGoogle}
            render={renderProps => (
                <button onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className={styles.logOut}>
                    Logout
                </button>
            )}
        >
        </GoogleLogout>
    );
};

export const LogoutButton = connect(
    (state: AppStateType) => ({
    }),
    {logOutGoogle}
)(LogoutButtonC)