import React, {useEffect, useRef, useState} from "react";
import styles from "../components/ListCv/ListCvItem.module.scss"
import Skills from "../components/Skills/Skills";
import pdfIcon from "../assets/images/pdfIcon.svg";
import defaultAvatar from "../assets/images/defaultUserPicture.svg";
import {NavLink} from "react-router-dom";
import {CvType, experienceType} from "../common/types";
import Language from "../components/Language/Language";
import TextTruncate from 'react-text-truncate';
import ReactTooltip from 'react-tooltip';

const ShortCvItem = ({shortCvItem}: { shortCvItem: CvType }): JSX.Element => {
    const dataCv = shortCvItem.document

    const [hiddenCounts, setHiddenCounts] = useState(0)


    const useElementWidth = () => {          //hook calculate width skill container
        const ref: any = useRef();
        const [width, setWidth] = useState<null | number>(null);

        const observer = useRef(
            new ResizeObserver((entries) => {
                const {width} = entries[0].contentRect;
                setWidth(width);
            })
        );
        useEffect(() => {
            observer.current.observe(ref.current);
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.current.disconnect()
            }
        }, [ref, observer]);
        return [ref, width];
    }

    const [refShortCvItem, width] = useElementWidth();

    useEffect(() => {                   //calculates blocks not included in the string
        let widthContainer = width
        let count = shortCvItem.document.skills.length
        shortCvItem.document.skills.forEach((el, i) => {
            // @ts-ignore
            let widthSkill = document.getElementById(`div${i}${shortCvItem.pk}`).clientWidth
            widthContainer = widthContainer - widthSkill - 8
            if (width > 50) count -= 1

        })
        setHiddenCounts(count)
    }, [shortCvItem.document.skills, shortCvItem.pk, width])

    const DefaultAvatar = () => {
        if (shortCvItem.avatar) {
            return shortCvItem.avatar
        } else return defaultAvatar
    }

    const onErrorAvatar = (e: any) => {
        e.target.src = defaultAvatar
    }

    const sinceCalculation = () => {
        let sinceYear
        if (shortCvItem.document.experience) {
            let startDates = shortCvItem.document.experience.map((e: experienceType) => {
                if (e.start) {
                    if (e.start.match(/^[a-zA-Z]{3}\s\d{2}\s\d{4}$/)) {
                        return e.start.substring(7, 11)
                    } else return 0
                } else return 0
            })
            sinceYear = Math.min.apply(null, startDates.map((y: string | 0) => Number(y)))
        } else sinceYear = 0
        if (sinceYear !== 0) {
            return `since ${sinceYear}`
        } else return null
    }

    const isDraft = () => {
        if (!dataCv.general.name ||
            !dataCv.general.specialisation ||
            (dataCv.skills === undefined || !dataCv.skills.length) ||
            (dataCv.general.languages === undefined || !dataCv.general.languages.length)) {
            return true
        }
    }

    const lastUpdateConverter = () => {
        return shortCvItem.updated.substring(0, 10)
    }

    let skillRender = dataCv.skills.map((skill, index) =>
        <Skills skill={skill} index={index} id={shortCvItem.pk} key={index + shortCvItem.pk}/>
    )

    const StatusCv = ({status, tipText}: { status: string, tipText: string }) => {
        return (
            <div>
                <div className={`${styles.cvIndicatorStatus} ${status}`}
                     data-tip={tipText}
                     data-for={`statusTip${shortCvItem.pk}`}
                >
                </div>

                <ReactTooltip id={`statusTip${shortCvItem.pk}`}
                              place="left"
                              effect="solid"
                              type="light"
                              className={styles.customToolTip}/>
            </div>
        )
    }
    return (
        <NavLink to={`/cv/${shortCvItem.pk}`}>
            <div className={styles.cvWrapper}>
                <div className={styles.shortCvBlock}>
                    <div>
                        <div className={styles.shortCvHeader}>
                            <div className={styles.imageAndNameCv}>
                                <div className={isDraft()
                                    ? `${styles.imageCv} ${styles.draftStyle}`
                                    : styles.imageCv}
                                >
                                    <div className={styles.borderImageCv}>
                                        <img src={DefaultAvatar()} alt={''} onError={onErrorAvatar}/>
                                    </div>
                                    <div className={styles.pdfIcon}>
                                        <div>
                                            <img src={pdfIcon} alt={'pdf'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.titleCv}>
                                    <div className={isDraft() ? `${styles.name} ${styles.draftStyle}` : styles.name}>
                                        {dataCv.general.name}
                                    </div>
                                    <div>
                            <span
                                className={isDraft() ? `${styles.profession} ${styles.draftStyle}` : styles.profession}>
                                {shortCvItem.document.general.specialisation}
                            </span>
                                        <span
                                            className={isDraft() ? `${styles.since} ${styles.draftStyle}` : styles.since}>{sinceCalculation()}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {
                                isDraft()
                                    ? <StatusCv status={styles.draftStatus} tipText={'CV not complete'}/>
                                    : shortCvItem.is_active
                                        ? <StatusCv status={styles.activeStatus} tipText={'CV is active'}/>
                                        : <StatusCv status={styles.inactiveStatus} tipText={`CV isn't active`}/>
                            }
                        </div>

                        <div className={styles.listSkills} ref={refShortCvItem}>
                            <div className={styles.skillsInLine}>
                                {skillRender}
                            </div>
                            {
                                hiddenCounts ?
                                    <div className={styles.moreSkills}>
                                        <span>{hiddenCounts} MORE</span>
                                    </div> :
                                    null
                            }

                        </div>

                        <div
                            className={isDraft() ? `${styles.descriptionCv} ${styles.draftText}` : styles.descriptionCv}>
                            <TextTruncate
                                line={3}
                                element="span"
                                truncateText="…"
                                text={dataCv.general.description}
                            />
                        </div>
                    </div>

                    <div className={styles.shortCvFooter}>
                        <Language languages={dataCv.general.languages}/>
                        <div>
                            <span>last update {lastUpdateConverter()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>

    )
}

export default ShortCvItem