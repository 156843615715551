import {Field} from "react-final-form";
import React, {useState} from "react";
import Input from "../Input/Input";
import styles from "./componentsForm/CustomFields.module.scss";
import TextArea from "../TextArea/TextArea";
import {SkillsForm} from "./componentsForm/SkillsForm";
import deleteFrameIcon from "../../assets/images/DeleteFrameIcon.svg";
import InputStartData from "../Input/InputStartData";
import InputEndData from "../Input/InputEndData";

export const Experience = ({
                               name,
                               index,
                               fields,
                           }: {
    name: string,
    index: number,
    fields: any

}) => {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    return (
        <div key={index} className={styles.field}>
            <label></label>
            <div className={index ? styles.separator : styles.separatorOff}
            ></div>

            <div className={styles.deleteWrap}>
                {
                    fields.length > 1 ?
                        <div onClick={() => fields.remove(index)}
                             className={styles.delete}>
                            <img src={deleteFrameIcon} alt=""/>
                        </div> :
                        <></>
                }
            </div>

            <Field
                name={`${name}.title`}
                defaultValue={''}
            >
                {({input, meta}) => (
                    <Input
                        placeholder={"Title"}
                        input={input}
                        type={"text"}
                        meta={meta}
                    />
                )}
            </Field>
            <div className={styles.inputsOnString}>
                <Field name={`${name}.start`}
                       component="input"
                       validate={undefined}
                       defaultValue={''}
                >
                    {({input, meta}) => (
                        <InputStartData
                            placeholder={"Start year"}
                            input={input}
                            meta={meta}
                            type={"date"}
                            startDate={startDate}
                            endDate={endDate}
                            // @ts-ignore
                            setStartDate={setStartDate}
                            // @ts-ignore
                            setEndDate={setEndDate}

                        />
                    )}
                </Field>
                <span>to</span>
                <Field name={`${name}.end`}
                       component="input"
                       defaultValue={''}
                >
                    {({input, meta}) => (
                        <InputEndData
                            placeholder={"End year"}
                            input={input}
                            meta={meta}
                            type={"date"}
                            startDate={startDate}
                            endDate={endDate}
                            // @ts-ignore
                            setStartDate={setStartDate}
                            // @ts-ignore
                            setEndDate={setEndDate}
                        />
                    )}
                </Field>
            </div>
            <Field
                name={`${name}.company`}
                component="input"
                defaultValue={''}
            >
                {({input, meta}) => (
                    <Input
                        placeholder={"Company"}
                        input={input}
                        meta={meta}
                        type={"text"}
                    />
                )}
            </Field>

            <Field
                name={`${name}.description`}
                component="input"
                defaultValue={''}
            >
                {props => (
                    <TextArea
                        placeholder={"Description"}
                        value={props.input.value}
                        onChange={props.input.onChange}
                    />
                )}
            </Field>
            <Field
                name={`${name}.skills`}>
                {({input, meta}) => (
                    <SkillsForm input={input}
                                meta={meta}

                    />
                )}
            </Field>

        </div>
    )
}
