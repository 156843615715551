import React, {useEffect, useState} from "react";
import styles from "./Authorization.module.scss"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";
import googleIcon from "../../assets/images/googleIcon.svg";
import {Button} from "../Button";
import eye from "../../assets/images/eye.svg";
import eye_off from "../../assets/images/eye_off.svg";
import {Redirect} from "react-router-dom";


export const Authorization = () => {

    const  [email, setEmail] = useState('')
    const  [password, setPassword] = useState('')
    const  [rememberMe, setRememberMe] = useState(false)
    const  [isShowPass, setIsShowPass] = useState(false)
    const  [isValid, setIsValid] = useState(false)
    const  [toSignUp, setToSignUp] = useState(false);
    const  [toForgotPass, setToForgotPass] = useState(false);


    const changeInputEmail = (data: any) => {
        setEmail(data.currentTarget.value)
    }

    const changeInputPassword = (data: any) => {
        setPassword(data.currentTarget.value)
    }
    const changeInputRememberMe = (data: any) => {
        setRememberMe(data.target.checked)
    }

    const showPass = () => {
        return setIsShowPass(!isShowPass)
    }

    const emailValidate = (checkEmailData: string) => {
        const reg = /\S+@\S+\.\S+/
        return reg.test(checkEmailData)
    }


    useEffect(()=> {
        if (emailValidate(email) && password.length) {
            setIsValid(true)
        } else setIsValid(false)
    },[email, password])

    const loginSubmit = () => {
        const sendData = {
            'login': email,
            'password': password,
            'rememberMe': rememberMe
        }
        alert(JSON.stringify(sendData))
    }

    if (toSignUp) {
        return <Redirect to='/test/create-account/'/>
    }
    if (toForgotPass) {
        return <Redirect to='/test/reset-pass/'/>
    }

    return (
        <div className={styles.container}>
            <div className={styles.authWindow}>
                <div className={styles.header}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h1>Welcome Back</h1>
                </div>

                <form action="#" className={styles.authForm}>
                    <input placeholder={'Email'}
                           type={'text'}
                           value={email}
                           onChange={changeInputEmail}/>
                    <input placeholder={'Password'}
                           type={isShowPass ? 'text' : 'password'}
                           value={password}
                           onChange={changeInputPassword}
                    />
                    <img src={isShowPass ? eye : eye_off}
                         onClick={()=> showPass()}
                         alt="showPass"
                         className={styles.showIcon}/>

                    <div className={styles.checkLine}>
                        <input type="checkbox"
                               className={styles.checkBox}
                               id={'check'}
                               onChange={changeInputRememberMe}
                               checked={rememberMe}

                        />
                        <label htmlFor={'check'}>Remember me</label>
                        <a href="#">
                            <span onClick={()=> setToForgotPass(true)}>Forgot password?</span>
                        </a>
                    </div>
                    <Button onClickAction={loginSubmit}
                            buttonText={'Log in'}
                            buttonStyle={'blueButton'}
                            isDisabled={!isValid}/>


                <div className={styles.separator}>
                    OR
                </div>

                <Button onClickAction={loginSubmit}
                        buttonText={'Continue with Google'}
                        buttonStyle={'whiteButton'}
                        buttonPict={googleIcon}
                />
                </form>


                <div className={styles.footer}>
                    <div>Don’t have an account?</div>
                    <a onClick={()=> setToSignUp(true)}>Sign up</a>
                </div>
            </div>
        </div>
    )
}