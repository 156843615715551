import React, {useEffect, useState} from "react";
import styles from "./Authorization.module.scss"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";
import {Button} from "../Button";
import ReCAPTCHA from "react-google-recaptcha";
import eye from "../../assets/images/eye.svg";
import eye_off from "../../assets/images/eye_off.svg";
import {Redirect} from "react-router-dom";


export const NewPassword = () => {

    const  [password, setPassword] = useState('')
    const  [isShowPass, setIsShowPass] = useState(false)
    const  [isValid, setIsValid] = useState(false)
    const [toSignIn, setToSignIn] = useState(false);

    const changeInputPassword = (data: any) => {
        setPassword(data.currentTarget.value)
    }

    const showPass = () => {
        return setIsShowPass(!isShowPass)
    }

    useEffect(()=> {
        if (password.length) {
            setIsValid(true)
        } else setIsValid(false)
    },[password])

    const sendNewPassword = () => {
        const sendData = {
            'newPass': password,
        }
        alert(JSON.stringify(sendData))
    }

    if (toSignIn) {
        return <Redirect to='/test/authorization/'/>
    }

    return (
        <div className={styles.container}>
            <div className={styles.authWindow}>
                <div className={styles.header}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h1>Create new password</h1>
                </div>
                <div className={styles.notation}>
                    <div>Please set the new password for your</div>
                    <div>account.</div>
                </div>

                <form action="#" className={styles.authForm}>
                    <input placeholder={'Password'}
                           type={isShowPass ? 'text' : 'password'}
                           value={password}
                           onChange={changeInputPassword}
                    />
                    <img src={isShowPass ? eye : eye_off}
                         onClick={()=> showPass()}
                         alt="showPass"
                         className={styles.showIcon}/>
                    <div className={styles.break}></div>
                    <Button onClickAction={sendNewPassword}
                            buttonText={'Create new password'}
                            buttonStyle={'blueButton'}
                            isDisabled={!isValid}/>
                </form>

                <div className={styles.footer}>
                    <div>Have an account?</div>
                    <a onClick={() => setToSignIn(true)}>Sign in</a>
                </div>
            </div>
        </div>
    )
}