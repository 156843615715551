import React from "react";
import styles from "../components/Main/Main.module.scss";
import emptyList from "../assets/images/illustrations.png";


export const EmptyListCv = ({newCv}: {newCv: () => Promise<void> } ) => {
    return (
        <div className={styles.emptyList}>
            <img src={emptyList} alt="plug"/>
            <div>
                <h2>No CVs created yet</h2>
            </div>
            <div>
                <span className={styles.planText}>Click on the</span>
                <span onClick={()=> newCv()} className={styles.addCvText}> add CV </span>
                <span className={styles.planText}>button to create your first CV</span>

            </div>
        </div>
    )
}