import React, {useState} from 'react'
import styles from './ShareCvModal.module.scss'
import {CvType} from "../../../common/types"
import loader from "../../../assets/images/treeDotsLoader.svg"
import {LinkIcon, RefreshIcon, DuplicateIcon, XIcon, ExclamationIcon} from "@heroicons/react/outline";
import {CheckIcon} from "@heroicons/react/solid";

export const ShareCvModal = ({getLinkPublicCvAction, currentCv, isLoading, closeModal}:
                                 {
                                     getLinkPublicCvAction: (pk: string) => void,
                                     currentCv: CvType,
                                     isLoading: boolean,
                                     closeModal: () => void
                                 }) => {

    const [isShowWarning, setIsShowWarning] = useState(false)

    if (!currentCv.secret && !isLoading) {
        getLinkPublicCvAction(currentCv.pk)
    }

    let location = window.location.href.split('/cv')[0]

    let uniqueLink = `${location}/public/${currentCv.pk}/${currentCv.secret}`

    const [isShowCopyLinkComplete, setIsShowCopyLinkComplete] = useState(false)
    const [isShowLinkRefreshed, setIsShowLinkRefreshed] = useState(false)

    const refreshLink = () => {
        setIsShowCopyLinkComplete(false)
        setIsShowLinkRefreshed(false)
        getLinkPublicCvAction(currentCv.pk)
        setIsShowWarning(false)
        if (!isLoading) {
            setTimeout(() => setIsShowLinkRefreshed(true), 800)
        }
    }

    const copyLink = () => {
        setIsShowLinkRefreshed(false)
        navigator.clipboard.writeText(uniqueLink)
        setTimeout(() => setIsShowCopyLinkComplete(true), 300)
    }

    const ShareWindow = () => {
        return (
            <>
                <div className={styles.modalShareHeader}>
                    <LinkIcon className={`${styles.linkIcon} ${styles.bigIcon}`}/>
                    <span>Copy the Link to share your CV</span>

                    <div className={styles.crossWrapper}>
                        <XIcon className={styles.cancelCross}
                               onClick={() => closeModal()}/>
                    </div>
                </div>
                <div>
                    <span>This is a unique link where people will be able to see your cv.</span>
                </div>
                <div className={styles.modalShareCopyModule}>
                    <LinkIcon className={styles.smallLinkIcon}/>
                    <div className={styles.shareBlock}>
                        {
                            isLoading
                                ? <img src={loader} alt="loader" className={styles.loader}/>
                                : <span className={styles.linkStroke}>{uniqueLink}</span>
                        }
                        <div className={styles.buttonsBlock}>

                            <button className={styles.grayButton}
                                    onClick={() => setIsShowWarning(true)}>
                                <RefreshIcon className={styles.smallIcon}/>
                                <span>Refresh</span>
                            </button>
                            <button className={styles.blueButton}
                                    onClick={() => copyLink()}>
                                <DuplicateIcon className={styles.smallIcon}/>
                                <span>Copy link</span>
                            </button>

                        </div>
                    </div>
                </div>

            </>
        )
    }

    const WarningWindow = () => {
        return (
            <>
                <div className={styles.modalShareHeader}>
                    <ExclamationIcon className={styles.bigIcon}/>
                    <span>Warning</span>
                    <div className={styles.crossWrapper}>
                        <XIcon className={styles.cancelCross}
                               onClick={() => closeModal()}/>
                    </div>
                </div>
                <div>
                    <span>Old link will not work. Are you sure?</span>
                </div>
                <div className={styles.modalWarningButtonModule}>
                    <button className={styles.grayButton}
                            onClick={() => refreshLink()}>
                        <RefreshIcon className={styles.smallIcon}/>
                        <span>Refresh</span>
                    </button>
                    <button className={styles.blueButton}
                            onClick={() => setIsShowWarning(false)}>
                        <span>Cancel</span>
                    </button>
                </div>
            </>
        )
    }

    return (
        <div className={styles.modalContent}>
            {
                isShowWarning
                ? <WarningWindow/>
                : <ShareWindow/>
            }
            <div className={
                isShowCopyLinkComplete
                    ? `${styles.copyComplete} ${styles.show} `
                    : styles.copyComplete
            }>
                <CheckIcon className={styles.smallIcon}/>
                <span>Link copied!</span>
            </div>
            <div className={
                isShowLinkRefreshed
                    ? `${styles.copyComplete} ${styles.show} `
                    : styles.copyComplete
            }>
                <CheckIcon className={styles.smallIcon}/>
                <span>Refreshed!</span>
            </div>
        </div>
    )
}

