import React, {ComponentType, useEffect} from "react";
import {connect} from "react-redux";
import {Preloader} from "../../assets/Preloader/Preloader";
import {
    selectorCurrentCv,
    selectorIsLoading, selectorIsLoadingPdf, selectorIsNewCv, selectorResponseError, selectorResponseSuccessDelete,
} from "../../redux/cv/selectors";
import {CurrentCvType} from "../../common/types";
import {Cv} from "../../pages/Cv";
import {deleteCv, getCvPdf, getLinkPublicCvAction, postCvForm, setCurrentCv} from "../../redux/cv/actions";
import {Redirect, withRouter} from "react-router-dom";
import {compose} from "redux";
import {AppStateType} from "../../redux/store";
import styles from "../Main/Main.module.scss";
import {HeaderCurrentCv} from "../Headers/CurrentCvHeader";
import {selectorIsLogin} from "../../redux/login/selectors";
import {ErrorShowComponent} from "../ErrorShowComponent/ErrorShowComponent";

const CurrentCvC = ({
                        isLoading,
                        currentCv,
                        setCurrentCv,
                        isNewCv,
                        match,
                        isLogin,
                        responseError,
                        isLoadingPdf,
                        getLinkPublicCvAction,
                        postCvForm,
                        deleteCv,
                        isSuccessDelete,
                        getCvPdf
                    }: CurrentCvType): JSX.Element => {

    let token = localStorage.getItem(`token`)

    useEffect(() => {
        setCurrentCv(match.params.cvId);
    }, [setCurrentCv, match.params.cvId])

    if (isNewCv) return <Redirect to={`/edit-cv/${currentCv.pk}`}/>

    if (!isLogin && (token === '')) {
        return <Redirect to={'/login/'}/>
    }

    if (Object.keys(responseError).length !== 0) {
        return <ErrorShowComponent errorText={responseError}/>
    }

    return (
        <div className={styles.flexContainer}>
            <HeaderCurrentCv
                currentCv={currentCv}
                getLinkPublicCvAction={getLinkPublicCvAction}
                postCvForm={postCvForm}
                deleteCv={deleteCv}
                isSuccessDelete={isSuccessDelete}
                isLoading={isLoading}
                getCvPdf={getCvPdf}

            />
            <section>
                {(isLoading || !currentCv.pk || isLoadingPdf) ?
                    <Preloader/> :
                    <Cv currentCv={currentCv}/>
                }
            </section>
        </div>
    )
}

export const CurrentCv = compose<ComponentType>(
    withRouter,
    connect(
        (state: AppStateType) => ({
            isLoading: selectorIsLoading(state),
            currentCv: selectorCurrentCv(state),
            isNewCv: selectorIsNewCv(state),
            isLogin: selectorIsLogin(state),
            responseError: selectorResponseError(state),
            isLoadingPdf: selectorIsLoadingPdf(state),
            isSuccessDelete: selectorResponseSuccessDelete(state),

        }),
        {
            setCurrentCv,
            deleteCv,
            postCvForm,
            getLinkPublicCvAction,
            getCvPdf
        })
)(CurrentCvC)
