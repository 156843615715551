import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {cvReducer} from "./cv/reducers";
import {loginReducer} from "./login/reducers";


const rootReducer = combineReducers({
    cv: cvReducer,
    login: loginReducer
})

type rootReducerType = typeof rootReducer
export type AppStateType = ReturnType<rootReducerType>

// typing all Action types
type PropertiesTypes<T> = T extends {[key: string]: infer U} ? U: never
export type InferActionTypes<T extends {[key: string]: (...args: any[])=>any}> = ReturnType<PropertiesTypes<T>>

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export default store;