import {PixelCrop} from 'react-image-crop'

export const getCroppedImg = (
    image: HTMLImageElement,
    crop: PixelCrop,
        ) => {
     {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const base64Image = canvas.toDataURL("image/*", 1);

        const  dataURLtoFile = (dataUrl: string, filename: string) => {
            let arr = dataUrl.split(','),
                // @ts-ignore
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
        return (dataURLtoFile(base64Image, 'avatar.png'));
    }
};