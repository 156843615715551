import React from "react";
import styles from "./UserModals.module.scss"
import logoWhite from "../../../assets/images/defaultCvLogoWhite_2-01.svg"
import {LogoutButton} from "../../LogoutButton/LogoutButton";
import {CvType, LoginUserType} from "../../../common/types";
import {initialData} from "../../../common/formCvConstants";
import defaultAvatar from "../../../assets/images/defaultUserPicture.svg";


export const UserModal = ({loginUser, isSaving, createNewCv}:
                      {loginUser: LoginUserType
                          isSaving: boolean,
                          createNewCv: (newCv: CvType, id: string) => void
                      }): JSX.Element => {


    const newCv = async () => {
        let newData = initialData()
        createNewCv(newData, newData.pk)
    };

    const onErrorAvatar = (e: any) => {
        e.target.src = defaultAvatar
    }

    return (
        <div className={styles.sideBarContainer}>
            <img src={logoWhite} alt="" className={styles.logo}/>
            <div className={styles.loggedUser}>
                <div className={styles.loggedUserImageContainer}>
                    <div className={styles.firstRing}>
                        <div className={styles.secondRing}>
                            <div className={styles.loggedUserImageNoImage}>
                                <img src={defaultAvatar} alt=""/>
                                <img src={loginUser.imageUrl} alt=""
                                     onError={onErrorAvatar}
                                     className={styles.loggedUserImage}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.loggedUserFirstName}>{loginUser.givenName}</div>
                <div className={styles.loggedUserLastName}>{loginUser.familyName}</div>
                <div className={styles.loggedUserMail}>{loginUser.email}</div>
                <button className={styles.buttonAddCv}
                        disabled={isSaving}
                        onClick={() => newCv()}>
                    <div>Add CV</div>
                </button>
            </div>
            <LogoutButton />
        </div>
    )
}
