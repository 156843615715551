import {Dispatch} from "redux";
import * as constants from "./constants";
import {ThunkAction} from "redux-thunk";
import {AppStateType, InferActionTypes} from "../store";
import {CurrentCvActionType} from "../cv/actions";

//Action creators
export type LoginActionType = InferActionTypes<typeof actions>

export const actions = {
    isLoginAC: (isLogin: boolean, loginUser: any) => ({type: constants.IS_LOGIN, isLogin, loginUser} as const),
    isLoginErrorAC: (isLoginError: boolean) => ({type: constants.IS_LOGIN_ERROR, isLoginError} as const),
    isLoginProcessAC: (isLoginProcess: boolean) => ({type: constants.IS_LOGIN_PROCESS, isLoginProcess} as const),
    errorLoginResponseAC: (errorLoginResponse: string) => ({type: constants.ERROR_LOGIN_RESPONSE, errorLoginResponse} as const)
}

// Thunk
export type DispatchType = Dispatch<LoginActionType>
export type ThunkType = ThunkAction<void, AppStateType, unknown, CurrentCvActionType>
type ResponseType = any;

export const logInGoogle = (response: ResponseType): ThunkType => {
    return async (dispatch: DispatchType) => {
        dispatch(actions.isLoginProcessAC(true))
        dispatch(actions.isLoginErrorAC(false))
        if (response.googleId) {
            localStorage.setItem( 'token', response.tokenId)
            dispatch(actions.isLoginAC(true, response.profileObj));
            dispatch(actions.isLoginErrorAC(false));
            dispatch(actions.isLoginProcessAC(false))
        } else if (response.error) {
            dispatch(actions.isLoginAC(false, {}));
            localStorage.setItem( 'token', '')
            dispatch(actions.errorLoginResponseAC(response.error))
            dispatch(actions.isLoginErrorAC(true));
            dispatch(actions.isLoginProcessAC(false))
        }
    }
}

export const logOutGoogle = (): ThunkType => {
    return  (dispatch: DispatchType) => {
        localStorage.setItem( 'token', '')
        dispatch(actions.isLoginAC(false, {}));
        dispatch(actions.isLoginErrorAC(false));
    }
}
