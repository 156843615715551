import React from "react";
import loaderAnimate from "../images/Spinner.svg"
import styles from "./Preloader.module.scss"


export const PreloaderCircle: React.FC = ()=> {

    return (
        <div className={styles.wrapper}>
            <img src={loaderAnimate} alt="please wait"/>
        </div>
    )
}

