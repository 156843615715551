import React from "react";
import styles from "./Input.module.scss"
import {FieldInputProps, FieldMetaState} from "react-final-form";

type InputType = {
    placeholder: string
    input?: FieldInputProps<string, HTMLElement>
    type: string
    meta?: FieldMetaState<string>
}
const Input = ({placeholder, input, meta, type}: InputType):JSX.Element => {

     return (
        <div className={ meta?.error && meta.touched ?
                        `${styles.inputContainer} ${styles.inputValidate}`:
                        styles.inputContainer}>
            <input placeholder={placeholder}
                   {...input}
                   type={type}
                   />
            <div className={input?.value ?
                `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                 styles.upperPlaceholder
            }>
                {placeholder}

            </div>
            {/*{meta?.error && meta.touched && <div className={styles.validation}>{meta.error}</div>}*/}
        </div>
    )
}

export default Input