export const refreshTokenSetup = (res) => {
     console.log('refresh start')
    // let date = Date.now()
    let refreshTiming = (res.tokenObj.expires_in || 3400 - 5 * 60) * 1000
    // console.log('expres in',res.tokenObj.first_issued_at)
    // console.log('date now', date)
    const refreshToken = async  () => {
        const newAuthRes = await res.reloadAuthResponse()
        refreshTiming = (newAuthRes.expires_in || 3400 - 5 * 60) * 1000
        localStorage.setItem( 'token', newAuthRes.id_token)
        setTimeout(refreshToken, refreshTiming)
        console.log('refresh')
    }
    setTimeout(refreshToken, refreshTiming)
}