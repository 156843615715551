import React, {useEffect, useState} from "react";
import styles from "./Authorization.module.scss"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";
import {Button} from "../Button";
import ReCAPTCHA from "react-google-recaptcha";
import {Redirect} from "react-router-dom";


export const ResetPassword = () => {

    const  [email, setEmail] = useState('')
    const  [captcha, setCaptcha] = useState(false)
    const  [isValid, setIsValid] = useState(false)
    const [toSignIn, setToSignIn] = useState(false);
    const [toCheckMail, setToCheckMail] = useState(false);

    const changeInputEmail = (data: any) => {
        setEmail(data.currentTarget.value)
    }

    const emailValidate = (checkEmailData: string) => {
        const reg = /\S+@\S+\.\S+/
        return reg.test(checkEmailData)
    }

    useEffect(()=> {
        if (emailValidate(email)) {
            setIsValid(true)
        } else setIsValid(false)
    },[email])

    const sendEmailForReset = () => {
        setToCheckMail(true)
    }

    const recaptchaOnChange = (value: any) => {
        setCaptcha(value)
    }

    if (toSignIn) {
        return <Redirect to='/test/authorization/'/>
    }

    if (toCheckMail) {
        return <Redirect to='/test/check-email/'/>
    }

    return (
        <div className={styles.container}>
            <div className={styles.authWindow}>
                <div className={styles.header}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h1>Reset your password</h1>
                </div>
                <div className={styles.notation}>
                    <div>Enter your email address associated</div>
                    <div>with you account.</div>
                </div>

                <form action="#" className={styles.authForm}>
                    <input placeholder={'Email'}
                           type={'text'}
                           value={email}
                           onChange={changeInputEmail}/>

                    <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_KEY_RECAPTCHA}`}
                        onChange={recaptchaOnChange}
                        className={styles.recaptcha}
                    />

                    <Button onClickAction={sendEmailForReset}
                            buttonText={'Reset password'}
                            buttonStyle={'blueButton'}
                            isDisabled={!isValid}/>
                </form>

                <div className={styles.footer}>
                    <div>Have an account?</div>
                    <a onClick={() => setToSignIn(true)}>Sign in</a>
                </div>
            </div>
        </div>
    )
}