import React from 'react';
import './App.css';
import {Main} from "./components/Main/Main";
import {connect} from "react-redux";
import {logInGoogle} from "./redux/login/actions";
import {Preloader} from "./assets/Preloader/Preloader";
import {AppStateType} from "./redux/store";
import {
    selectorIsLogin,
    selectorIsLoginProcess
} from "./redux/login/selectors";

import {AppType} from "./common/types";
import HandleLogin from "./auth/HandleLogin";

const AppC = ({
                  logInGoogle,
                  isLoginProcess,
                  isLogin,
              }: AppType) => {

    const loginLoaded = HandleLogin(logInGoogle)

    return (
        <div className="App">
            {
                isLoginProcess || !loginLoaded
                    ? <Preloader/>
                    : <Main isLogin={isLogin}/>
            }
        </div>
    );
}

export const App =
    connect(
    (state: AppStateType) => ({
        isLoginProcess: selectorIsLoginProcess(state),
        isLogin: selectorIsLogin(state),
    }), {logInGoogle}
)(AppC)