import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './EditAvatarModal.module.scss';
import {DownloadIcon, TrashIcon, XIcon} from "@heroicons/react/outline";
import ReactCrop, {centerCrop, Crop, makeAspectCrop, PixelCrop} from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import {canvasPreview} from "../../ImageCrop/canvasPreview"
import {useDropzone} from 'react-dropzone'
import defaultAvatar from "../../../assets/images/defaultUserPicture.svg";
import deleteFrameIcon from "../../../assets/images/DeleteFrameIcon.svg";
import {getCroppedImg} from "../../ImageCrop/getCroppedImg";
import {PreloaderCircle} from "../../../assets/Preloader/PreloaderCircle";


function EditAvatarModal({closeModal, avatar, postAvatarFunc, deleteAvatarFunc, isLoadingAvatar}:
                             {
                                 closeModal: () => void,
                                 avatar: string | undefined,
                                 postAvatarFunc: (e: any) => void
                                 deleteAvatarFunc: () => void
                                 isLoadingAvatar: boolean
                             }) {

    const [imgSrc, setImgSrc] = useState('')
    const [isAvatar, setIsAvatar] = useState(false)
    const [isSaveAvatar, setIsSaveAvatar] = useState(false)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [crop, setCrop] = useState<Crop>()
    const [isAlert, setIsAlert] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        onSelectFile(acceptedFiles)
        setIsAvatar(true)
    }, [])
    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg', '.png'],
        },
        useFsAccessApi: false
    })

    function resetAvatar() {
        setIsAvatar(false)
        setImgSrc('')
        setCrop(undefined)
    }

    function onSelectFile(e: any) {
        if (e) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e[0])
        }
    }

    function onImageLoad(e: any) {
        const {width, height} = e.currentTarget
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%', height: 100,
                }, 1, width, height
            ), width, height
        )
        setCrop(crop)
    }

    useEffect(() => {
        let makePreview = async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop
                )
            }
        }
        setTimeout(() => makePreview(), 100)
    }, [completedCrop, imgSrc])

    useEffect(() => {
        if (isSaveAvatar && imgRef.current && completedCrop) {
            const croppedImg = getCroppedImg(imgRef.current, completedCrop)
            postAvatarFunc(croppedImg)
            setIsSaveAvatar(false)
        }
    }, [completedCrop, isSaveAvatar, postAvatarFunc])

    const DeleteModal = () => {
        return (
            <div className={styles.centerBlock}>
                <div className={styles.previewWrapper}>
                    <img src={avatar}
                         alt={'avatar'}
                         className={styles.avatarPreview}/>
                </div>
                <div className={styles.alertTextWrap}>
                    <div>
                        <TrashIcon className={styles.bigIcon}/>
                        <h4>Are you sure you want<br/>to delete the picture?</h4>
                    </div>
                </div>
                <div className={styles.buttonBlock}>
                    <button className={styles.redButton}
                            onClick={() => deleteAvatarFunc()}>
                        Delete picture
                    </button>

                </div>
                <button className={styles.cancelButton}
                        onClick={() => setIsAlert(false)}>
                    Cancel
                </button>
            </div>
        )
    }

    const DefaultAvatar = () => {
        if ((avatar === defaultAvatar) && !isAvatar) {
            return (
                <img src={defaultAvatar}
                     alt={'avatar'}
                     className={styles.avatarPreview}
                />
            )
        } else if (isAvatar) {
            return <canvas className={styles.avatarPreview} ref={previewCanvasRef}/>
        } else return (
            <div>
                <div onClick={() => setIsAlert(true)}
                     className={styles.delete}>
                    <img src={deleteFrameIcon} alt="delete avatar"/>
                </div>
                <img src={avatar}
                     alt={'avatar'}
                     className={styles.avatarPreview}/>
            </div>
        )
    }

    return (
        <div className={styles.modalReport}>
            <div className={styles.modalHeader}>
                <XIcon className={styles.cancelCross}
                       onClick={() => closeModal()}/>
            </div>
            {
                isAlert
                    ? <DeleteModal/>
                    : <div className={styles.centerBlock}>
                        <div className={styles.previewWrapper}>
                            {isLoadingAvatar
                                ? <PreloaderCircle/>
                                : <DefaultAvatar/>
                            }

                        </div>
                        <div className={styles.imageWrap}>
                            {
                                isAvatar
                                    ? <ReactCrop
                                        crop={crop}
                                        onChange={c => setCrop(c)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={1}
                                        circularCrop={true}
                                        className={styles.loadedImageWrap}
                                    >
                                        <img
                                            ref={imgRef}
                                            src={imgSrc}
                                            alt={'avatar preview'}
                                            onLoad={onImageLoad}
                                        />
                                    </ReactCrop>
                                    : <div {...getRootProps()}
                                           className={styles.dragNDrop}>
                                        <div className={styles.border}>
                                            <input  {...getInputProps()}/>
                                            <DownloadIcon className={styles.bigIcon}/>
                                            {
                                                isDragActive ?
                                                    <p>Drop the files here ...</p> :
                                                    <p>Drag 'n' drop picture here, <br/>or click to select files</p>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className={styles.buttonBlock}>
                            <button className={styles.blueButton}
                                    onClick={() => setIsSaveAvatar(true)}>
                                Save picture
                            </button>

                            <button className={styles.grayButton}
                                    onClick={() => resetAvatar()}>
                                reset
                            </button>

                        </div>
                        <button className={styles.cancelButton}
                                onClick={() => closeModal()}>
                            Cancel
                        </button>
                    </div>
            }
        </div>
    )
}

export default EditAvatarModal