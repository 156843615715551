import React from "react";
import Linkify from 'linkify-react';
import {CvType, educationType, experienceType} from "../common/types";
import Skills from "../components/Skills/Skills";
import styles from "../components/CurrentCv/CurrentCv.module.scss";
import LanguageContainer from "../components/Language/Language";
import defaultAvatar from "../assets/images/defaultUserPicture.svg";

export const Cv = ({currentCv}: { currentCv: CvType }): JSX.Element => {
    const dataCv = currentCv.document

    const DefaultAvatar = () => {
        if (currentCv.avatar) {
            return currentCv.avatar
        } else return defaultAvatar
    }

    const onErrorAvatar = (e: any) => {
        e.target.src = defaultAvatar
    }

    const sinceCalculation = () => {
        let sinceYear
        if (currentCv.document.experience) {
            let startDates = currentCv.document.experience.map((e: experienceType) => {
                if (e.start) {
                    if (e.start.match(/^[a-zA-Z]{3}\s\d{2}\s\d{4}$/)) {
                        return e.start.substring(7, 11)
                    } else return 0
                } else return 0
            })
            sinceYear = Math.min.apply(null, startDates.map((y: string | 0) => Number(y)))
        } else sinceYear = 0
        if (sinceYear !== 0) {
            return `since ${sinceYear}`
        } else return null
    }

    let skillRender = (skills: string[]) => {
        return skills.map((skill, index) =>
            <Skills skill={skill} index={index} key={index + currentCv.pk}/>
        )
    }

    const dateConverter = (value: string) => {
        let month = value.toString().substring(0, 4)
        let year = value.toString().substring(7, 12)
       return `${month} ${year}`
    }

    const options = {target: '_blank'}

    const renderEducation = (cvEducation: educationType[]) => {
        return (
            cvEducation.map(e => <div className={styles.element} key={e.title + currentCv.pk}>
                <h4>{e.title}</h4>
                {
                    e.start && e.end ?
                        <div className={styles.educationData}>{dateConverter(e.start)}
                            <span> &mdash; </span>
                            {dateConverter(e.end)}
                        </div> :
                        <></>
                }
                    <Linkify className={styles.descriptionCv} tagName="p" options={options}>

                    {e.description}
                    </Linkify>
            </div>)
        )
    }

    const renderExperience = (cvExperience: experienceType[]) => {
        return (
            cvExperience.map(e => <div className={styles.element} key={e.title + currentCv.pk}>
                <h4>{e.title}</h4>
                {
                    e.start && e.end ?
                        <div className={styles.educationData}>{dateConverter(e.start)}
                            <span> &mdash; </span>
                            {dateConverter(e.end)}
                        </div> :
                        <></>
                }
                <div className={styles.company}>{e.company}</div>
                <Linkify className={styles.descriptionCv} tagName="p" options={options}>
                    {e.description}
                </Linkify>
                <div className={styles.skillsWrapExperience}>
                    {skillRender(e.skills)}
                </div>
            </div>)
        )
    }

    return (
        <div className={styles.currentCvWrapper}>
            <div className={styles.nameContainer}>
                <img src={DefaultAvatar()} alt={''} onError={onErrorAvatar}/>

                <div className={styles.name}>
                    <div>{dataCv.general.name}</div>
                </div>
            </div>

            <div>
                <LanguageContainer languages={dataCv.general.languages}/>
            </div>
            <div className={styles.profession}>
                <span>{dataCv.general.specialisation}</span>
                <span className={styles.since}>{sinceCalculation()}</span>
            </div>
            <Linkify className={styles.descriptionCv} tagName="p" options={options}>
                {dataCv.general.description}
            </Linkify>
            <div className={styles.descriptionCv} >

            </div>
            {
                dataCv.skills.length ?
                    <div>
                        <h2>Skills</h2>
                        <div className={styles.skillsWrap}>
                            {skillRender(dataCv.skills)}
                        </div>
                    </div> :
                    <></>
            }

            {dataCv.education.filter(e => e.title).length ?
                <>
                    <h2>Education</h2>
                    <div className={styles.partContainer}>
                        {renderEducation(dataCv.education)}
                    </div>
                </>
                :
                null
            }
            {dataCv.experience.filter(e => e.title).length ?
                <>
                    <h2>Experience</h2>
                    <div className={styles.partContainer}>
                        {renderExperience(dataCv.experience)}
                    </div>
                </>
                :
                null
            }

        </div>
    )
}
