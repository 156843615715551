import React from "react";
import styles from "./UserInHeader.module.scss";
import ReactModal from "react-modal";
import {UserModal} from "../../Modals/UserModal/UserModal";
import {connect} from "react-redux";
import {AppStateType} from "../../../redux/store";
import {selectorIsSaving} from "../../../redux/cv/selectors";
import {createNewCv} from "../../../redux/cv/actions";
import {SideBarType} from "../../../common/types";
import {selectorIsLogin, selectorLoginUser} from "../../../redux/login/selectors";
import defaultAvatar from "../../../assets/images/defaultUserPicture.svg";


const UserInHeaderC = ({isSaving, createNewCv, loginUser, isLogin}: SideBarType): JSX.Element => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const onErrorAvatar = (e: any) => {
        e.target.src = defaultAvatar
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className={styles.userPicture}>
            <div className={styles.loggedUserImageNoImage}  onClick={() => openModal()}>
                <img src={defaultAvatar} alt=""/>
                <img src={loginUser.imageUrl} alt="" onError={onErrorAvatar}
                     className={styles.loggedUserImage}/>
            </div>

            <ReactModal isOpen={modalIsOpen}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={closeModal}
                        overlayClassName="Overlay"
                        className="UserModal"
                        closeTimeoutMS={200}
            >
                {
                    isLogin
                        ? <UserModal loginUser={loginUser}
                                     isSaving={isSaving}
                                     createNewCv={createNewCv}/>
                        : <></>
                }

            </ReactModal>
        </div>
    )
}
export const UserInHeader = connect(
    (state: AppStateType) => ({
        isSaving: selectorIsSaving(state),
        loginUser: selectorLoginUser(state),
        isLogin: selectorIsLogin(state),
    }), {createNewCv}
)(UserInHeaderC)