import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ListCv} from "../components/ListCv";
import {CurrentCv} from "../components/CurrentCv";
import {Login} from "../components/Login";
import {PublicCv} from "../components/PublicCv";
import {CvFormContainer} from "../components/NewCvForm/CvFormContainer";
import {Authorization} from "../components/Login/Authorization";
import {ResetPassword} from "../components/Login/ResetPassword";
import {NewPassword} from "../components/Login/NewPassword";
import {CheckEmail} from "../components/Login/CheckEmail";
import {CreateAccount} from "../components/Login/CreateAccount";

export const Routes = () => {

    return (
        <Switch>
            <Route path='/public/:cvId/:secret'>
                <PublicCv/>
            </Route>
            <Route path='/login/'>
                <Login/>
            </Route>
            <Route path='/cv-list/'>
                <ListCv/>
            </Route>
            <Route path='/cv/:cvId'>
                <CurrentCv/>
            </Route>
            <Route path='/edit-cv/:cvId'>
                <CvFormContainer/>
            </Route>
            <Route path='/test/authorization/'>
                <Authorization/>
            </Route>
            <Route path='/test/reset-pass/'>
                <ResetPassword/>
            </Route>
            <Route path='/test/new-pass/'>
                <NewPassword/>
            </Route>
            <Route path='/test/check-email/'>
                <CheckEmail/>
            </Route>
            <Route path='/test/create-account/'>
                <CreateAccount/>
            </Route>
            <Route path='/'>
                <Redirect to='/login/'/>
            </Route>

        </Switch>

    )
}


