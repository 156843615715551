import React, {useEffect, useState} from 'react'
import './ImageCrop.module.scss'
import {connect} from "react-redux";
import {AppStateType} from "../../redux/store";
import {selectorCurrentCv, selectorIsLoadingAvatar, selectorPostedAvatar} from "../../redux/cv/selectors";
import {deleteAvatarForm, postAvatarForm} from "../../redux/cv/actions";
import {CvType} from "../../common/types";
import styles from "./ImageCrop.module.scss"
import addPicture from "./../../assets/images/addPicture.svg"
import defaultAvatar from "../../assets/images/defaultUserPicture.svg";
import {PreloaderCircle} from "../../assets/Preloader/PreloaderCircle";
import ReactModal from "react-modal";
import EditAvatarModal from "../Modals/EditAvatarModal/EditAvatarModal";




export const ImageCropC = ({postAvatarForm, currentCv, isLoadingAvatar, deleteAvatarForm, postedAvatar}:
                               {
                                   postAvatarForm: (avatar: any, id: string) => void,
                                   postedAvatar: string
                                   deleteAvatarForm: (id: string) => void,
                                   currentCv: CvType,
                                   isLoadingAvatar: boolean,
                               }): JSX.Element => {

    const [avatar, setAvatar] = useState(currentCv.avatar)
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function closeModal() {
        setModalIsOpen(false);
    }

    const postAvatarFunc = async (e: any) => {
        postAvatarForm(e, currentCv.pk)
        await closeModal()
    };

    const deleteAvatarFunc = async () => {
        deleteAvatarForm(currentCv.pk)
        await closeModal()
    };

    useEffect(() => {
        if (!avatar) {
            setAvatar(defaultAvatar)
        }
    },[avatar])

    useEffect(()=>{
        if (!isLoadingAvatar && postedAvatar) {
            setAvatar(postedAvatar)
            console.log('second effect')
        } else if (!isLoadingAvatar && postedAvatar === null) {
            setAvatar('')
        }
    }, [postedAvatar, isLoadingAvatar])


    return (
        <div className={styles.avatarWrap}>
            {
                isLoadingAvatar ?
                    <PreloaderCircle /> :
                    <div>
                        <img src={avatar} alt={'ava'}
                             className={styles.avatar} />
                        <img src={addPicture}
                             className={styles.addPict}
                             onClick={()=> setModalIsOpen(true)}
                             alt="addPict"/>
                    </div>
            }

            <ReactModal isOpen={modalIsOpen}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={closeModal}
                        overlayClassName="Overlay"
                        className="ShareModal"
                        closeTimeoutMS={200}
            >
                <EditAvatarModal avatar={avatar}
                                 closeModal={closeModal}
                                 postAvatarFunc={postAvatarFunc}
                                 deleteAvatarFunc={deleteAvatarFunc}
                                 isLoadingAvatar={isLoadingAvatar}
                />
            </ReactModal>
        </div>
    )
}

export const ImageCrop = connect(
    (state: AppStateType) => ({
        currentCv: selectorCurrentCv(state),
        isLoadingAvatar: selectorIsLoadingAvatar(state),
        postedAvatar: selectorPostedAvatar(state),
    }), {postAvatarForm, deleteAvatarForm}
)(ImageCropC)