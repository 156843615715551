import React from "react";
import styles from "./ErrorShowComponent.module.scss";
import {NavLink} from "react-router-dom";
import {ErrorType} from "../../common/types";
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";

export const ErrorShowComponent = ({errorText, errorLoginText}:
                                       {
                                           errorText?: ErrorType,
                                           errorLoginText?: string
                                       }): JSX.Element => {
    return (
        <section>
            <div className={styles.errorWrapper}>
                <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                <div className={styles.statusNumError}>
                    {errorText ?
                        errorText.status
                        : ''}
                </div>
                <div className={styles.statusTextError}>
                    {errorText
                        ? <span>Error: {errorText.statusText}</span>
                        : <div>
                            <div>Error: {errorLoginText}.</div>
                            {
                                errorLoginText === 'idpiframe_initialization_failed'
                                    ? <span>You can't sign in because your browser
                                is blocking third-party cookies.</span>
                                    : <div>Please, refresh page.</div>
                            }
                        </div>
                    }

                </div>
                {errorText
                    ? <NavLink to={`/`}>
                        <div className={styles.buttonBack}>
                            Back to start page
                        </div>
                    </NavLink>
                    : <>
                    </>
                }

            </div>
        </section>

    )
}
