import React from 'react';
import styles from './PolicyModals.module.scss';
import {XIcon} from "@heroicons/react/outline";
import pin from "../../../assets/images/Pioneers_pin_rs.svg";

function PolicyModal({closeModal}: { closeModal: () => void }) {


    return (
        <div className={styles.modalReport}>
            <XIcon className={styles.cancelCross}
                   onClick={() => closeModal()}/>
            <div>
                <img src={pin} className={styles.pin}/>
            </div>
            <div className={styles.attention}>
                privacy policy
            </div>
            <span className={styles.planText}>
I, joining the ranks of the All-Union Pioneer Organization,
                <br/> in the face of my comrades, solemnly swear:
                <br/> to love my Motherland passionately;
                <br/> to live, study and fight as the great Lenin bequeathed,
                <br/> as the Communist Party teaches;
                <br/> always comply with the laws of the pioneers
                <br/> of the Soviet Union
            </span>
            <button className={styles.cancelButton}
                    onClick={() => closeModal()}>Close
            </button>
        </div>
    )
}

export default PolicyModal