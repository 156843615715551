import React, {useEffect, useState} from "react";
import styles from "./Header.module.scss";
import {clearShortCv, setFilterCv} from "../../../redux/cv/actions";
import {connect} from "react-redux";
import {AppStateType} from "../../../redux/store";
import {selectorFilterCv} from "../../../redux/cv/selectors";
import findIcon from "../../../assets/images/findIcon.svg";
import resetFilterIcon from "../../../assets/images/resetFilterIcon-01.svg";
import {UserInHeader} from "../UserInHeader";
import {useHistory} from "react-router-dom";

const ListCvHeaderC = ({setFilterCv, clearShortCv}:
                           {setFilterCv: (typeFilterValue: string)=> void, clearShortCv: ()=> void}): JSX.Element => {

    const [typeFilterValue, setTypeFilterValue] = useState('');

    const onTypeFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTypeFilterValue(e.target.value);
    }

    const clearTypeFilterValue = () => {
        setTypeFilterValue('');
        setFilterCv('')
    }
    const sendTypeFilterValue = () => {
        setFilterCv(typeFilterValue);
    }

    const history = useHistory()

    useEffect(() => {
        let query = ''
        if (typeFilterValue) query = `?search=${typeFilterValue}`
        history.push({
            search: query
        })
        if (typeFilterValue.length >= 3){
            clearShortCv()
            sendTypeFilterValue()
        }
        if (typeFilterValue.length === 0){
            clearShortCv()
            clearTypeFilterValue()
        }
    }, [typeFilterValue])


    return (
        <header className={styles.headerContainer}>
            <div className={styles.inputFind}>
                <div className={styles.inputContainer}>
                    <img src={findIcon} alt="find Icon" className={styles.findIcon}/>

                    <input placeholder={'Search by CV'}
                           onChange={onTypeFilterValueChange}
                           value={typeFilterValue}
                           onKeyDown={(key) => {
                               if (key.code === "Enter" || key.code === "NumpadEnter") {
                                   sendTypeFilterValue()
                               }
                           }}/>
                    <img onClick={clearTypeFilterValue}
                         src={resetFilterIcon} alt="reset Filter"
                         className={typeFilterValue ?
                             styles.resetIcon :
                             `${styles.resetIcon} ${styles.hide}`}/>

                    <div className={typeFilterValue ?
                        `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                        styles.upperPlaceholder
                    }>
                        Search by CV
                    </div>
                </div>
            </div>
            {/*<div className={styles.filtersBar}>*/}
            {/*    <div className={styles.filters}>*/}
            {/*        <img src={filterIcon} alt="filter"/>*/}
            {/*        <span>Filters</span>*/}
            {/*    </div>*/}
            {/*    <div className={styles.filtersResetCounterBlock}>*/}
            {/*        <span className={styles.filterCounter}>3</span>*/}
            {/*        <span className={styles.resetFilter}>Reset</span>*/}
            {/*    </div>*/}
            {/*    <img src={arrow} alt="arrow"/>*/}
            {/*</div>*/}
            <UserInHeader/>
        </header>
    )
}

export const ListCvHeader = connect(
    (state: AppStateType) => ({
        filter: selectorFilterCv(state),
    }),
    {setFilterCv, clearShortCv})
(ListCvHeaderC)