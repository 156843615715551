import {NewCvFormType, CvType, ErrorType} from "../../common/types";
import * as constants from './constants';
import {CurrentCvActionType} from "./actions";

const initialState = {
    isLoading: false,
    isLoadingSkills: false,
    isLoadingAvatar: false,
    isSaving: false,
    isNewCv: false,
    fetchPaginator: true,
    offsetPaginator: 0,
    totalCvsCount: 0,
    shortCvs: [] as Array<CvType>,
    foundSkills: [] as Array<string>,
    currentCv: {} as CvType,
    newCvForm: {} as NewCvFormType,
    responseSuccess: false,
    responseSuccessDelete: false,
    responseSuccessPost: false,
    isLoadingPdf: false,
    pdfError: '',
    filter: '',
    postedAvatar: '',
    responseError: {} as ErrorType,
}

export const cvReducer = (state = initialState, action: CurrentCvActionType): typeof initialState => {
    switch (action.type) {
        case constants.IS_LOADING:
            return {...state, isLoading: action.isLoading}
        case constants.IS_LOADING_SKILLS:
            return {...state, isLoadingSkills: action.isLoadingSkills}
        case constants.IS_LOADING_AVATAR:
            return {...state, isLoadingAvatar: action.isLoadingAvatar}
        case constants.IS_SAVING:
            return {...state, isSaving: action.isSaving}
        case constants.IS_NEW_CV:
            return {...state, isNewCv: action.isNewCv}
        case constants.FETCH_PAGINATOR:
            return {...state, fetchPaginator: action.fetchPaginator}
        case constants.OFFSET_PAGINATOR:
            return {...state, offsetPaginator: action.offsetPaginator}
        case constants.TOTAL_CVS_COUNT:
            return {...state, totalCvsCount: action.totalCvsCount}
        case constants.GET_CURRENT_CV:
            return {...state, currentCv: action.currentCv}
        case constants.CLEAR_CURRENT_CV:
            return {...state, currentCv: {} as CvType}
        case constants.GET_SHORT_CV:
            return {...state, shortCvs: [...state.shortCvs, ...action.shortCvs]}
        case constants.FOUND_SKILLS:
            return {...state, foundSkills:  action.foundSkills}
        case constants.GET_PUBLIC_CV:
            return {...state, currentCv: {...state.currentCv, secret: action.secret}}
        case constants.CLEAR_SHORT_CV:
            return {...state, shortCvs: []}
        case constants.SET_FILTER:
            return {...state, filter: action.filter}
        case constants.RESPONSE_SUCCESS:
            return {...state, responseSuccess: action.responseSuccess}
        case constants.RESPONSE_ERROR:
            return {...state, responseError: action.responseError}
        case constants.RESPONSE_ERROR_CLEAN:
            return {...state, responseError: {} as ErrorType}
        case constants.RESPONSE_SUCCESS_DELETE:
            return {...state, responseSuccessDelete: action.responseSuccessDelete}
        case constants.POSTED_AVATAR:
            return {...state, postedAvatar: action.postedAvatar}
        case constants.RESPONSE_SUCCESS_POST:
            return {...state, responseSuccessPost: action.responseSuccessPost}
        case constants.IS_LOADING_PDF:
            return {...state, isLoadingPdf: action.isLoadingPdf}
        case constants.PDF_ERROR:
            return {...state, pdfError: action.pdfError}
        default:
            return state
    }
}

