import {AppStateType} from "../store";

export const selectorIsLoading = (state: AppStateType) => state.cv.isLoading;
export const selectorIsLoadingSkills = (state: AppStateType) => state.cv.isLoadingSkills;
export const selectorIsLoadingAvatar = (state: AppStateType) => state.cv.isLoadingAvatar;
export const selectorIsSaving = (state: AppStateType) => state.cv.isSaving;
export const selectorCurrentCv = (state: AppStateType) => state.cv.currentCv;
export const selectorIsNewCv = (state: AppStateType) => state.cv.isNewCv;
export const selectorShortCv = (state: AppStateType) => state.cv.shortCvs;
export const selectorFoundSkills = (state: AppStateType) => state.cv.foundSkills;
export const selectorFetchPaginator = (state: AppStateType) => state.cv.fetchPaginator;
export const selectorOffsetPaginator = (state: AppStateType) => state.cv.offsetPaginator;
export const selectorTotalCvsCount = (state: AppStateType) => state.cv.totalCvsCount;
export const selectorFilterCv = (state: AppStateType) => state.cv.filter;
export const selectorResponseSuccess = (state: AppStateType) => state.cv.responseSuccess;
export const selectorResponseSuccessDelete = (state: AppStateType) => state.cv.responseSuccessDelete;
export const selectorResponseSuccessPost = (state: AppStateType) => state.cv.responseSuccessPost;
export const selectorResponseError = (state: AppStateType) => state.cv.responseError;
export const selectorPostedAvatar = (state: AppStateType) => state.cv.postedAvatar;
export const selectorIsLoadingPdf = (state: AppStateType) => state.cv.isLoadingPdf;
export const selectorPdfError = (state: AppStateType) => state.cv.pdfError;
