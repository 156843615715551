import React, {ComponentType} from "react";
import {connect} from "react-redux";
import {Preloader} from "../../assets/Preloader/Preloader";
import {
    selectorCurrentCv,
    selectorIsLoading, selectorIsLoadingPdf, selectorResponseError,
} from "../../redux/cv/selectors";
import {PublicCvType} from "../../common/types";
import {Cv} from "../../pages/Cv";
import {getPublicCvPdf, showPublicCvAction} from "../../redux/cv/actions";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {AppStateType} from "../../redux/store";
import styles from "../Main/Main.module.scss";
import {HeaderPublicCv} from "../Headers/PublicCvHeader";
import {ErrorShowComponent} from "../ErrorShowComponent/ErrorShowComponent";

const PublicCvC = ({isLoading,
                       currentCv,
                       showPublicCvAction,
                       match,
                       responseError,
                       isLoadingPdf,
                       getPublicCvPdf}: PublicCvType): JSX.Element => {

    if (Object.keys(responseError).length !== 0) {
        return <ErrorShowComponent errorText={responseError}/>
    }

    if ((match.path === '/public/:cvId/:secret') && !currentCv.pk && !isLoading) {
        showPublicCvAction(match.params.cvId, match.params.secret)
    }

    return (
        <div className={styles.flexContainer}>
            <HeaderPublicCv
                currentCv={currentCv}
                getPublicCvPdf={getPublicCvPdf}
            />
            <section>
            {(isLoading || !currentCv.pk || isLoadingPdf) ?
                <Preloader/> :
                <Cv currentCv={currentCv}/>
            }
            </section>
        </div>
    )
}

export const PublicCv = compose<ComponentType>(
    withRouter,
    connect(
        (state: AppStateType) => ({
            isLoading: selectorIsLoading(state),
            currentCv: selectorCurrentCv(state),
            responseError: selectorResponseError(state),
            isLoadingPdf: selectorIsLoadingPdf(state),

        }),
        {showPublicCvAction, getPublicCvPdf})
)(PublicCvC)
