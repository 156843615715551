export const GET_CURRENT_CV = 'SET_CURRENT_CV';
export const IS_LOADING = 'IS_LOADING';
export const IS_LOADING_SKILLS = 'IS_LOADING_SKILLS';
export const IS_LOADING_AVATAR = 'IS_LOADING_AVATAR';
export const IS_SAVING = 'IS_SAVING';
export const CLEAR_CURRENT_CV = 'CLEAR_CURRENT_CV';
export const GET_SHORT_CV = 'SET_SHORT_CV';
export const FETCH_PAGINATOR = 'FETCH_PAGINATOR';
export const OFFSET_PAGINATOR = 'OFFSET_PAGINATOR';
export const TOTAL_CVS_COUNT = 'TOTAL_CVS_COUNT';
export const GET_PUBLIC_CV = 'SET_PUBLIC_CV';
export const FOUND_SKILLS = 'FOUND_SKILLS';
export const POSTED_AVATAR = 'POSTED_AVATAR';

export const SET_FILTER = 'SET_FILTER';
export const CLEAR_SHORT_CV = 'CLEAR_SHORT_CV';

export const RESPONSE_SUCCESS = 'RESPONSE_SUCCESS';
export const RESPONSE_SUCCESS_DELETE = 'RESPONSE_SUCCESS_DELETE';
export const RESPONSE_SUCCESS_POST = 'RESPONSE_SUCCESS_POST';

export const IS_NEW_CV = 'IS_NEW_CV';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';
export const RESPONSE_ERROR_CLEAN = 'RESPONSE_ERROR_CLEAN';

export const IS_LOADING_PDF = 'IS_LOADING_PDF';
export const PDF_ERROR = 'PDF_ERROR';