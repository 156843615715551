import {v4 as uuidv4} from 'uuid'

const saveChangedForm = () => {
    if (document.getElementById('cvForm')) {
        document.getElementById('cvForm')!
            .dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    }
}

export const saveForm = () => {
    saveChangedForm()
}

export const initialData = () => {
    return {
        pk: uuidv4(),
        title: "",
        document: {
            skills: [],
            general: {
                name: "",
                languages: [],
                description: "",
                specialisation: ""
            },
            education: [
                {
                    end: "",
                    start: "",
                    title: "",
                    description: ""
                },

            ],
            experience: [
                {
                    end: "",
                    start: "",
                    title: "",
                    skills: [],
                    company: "",
                    description: ""
                }
            ]
        },
        secret: "",
        is_active: false,
        updated: ""
    }
}

export const isEmpty = (obj: any) => {
    if (obj.skills.length !== 0) {
        return false
    }
    if ((obj.general.name !== undefined) && (obj.general.name !== "")) {
        return false
    }
    if (obj.general.languages.length !== 0) {
        return false
    }
    if ((obj.general.description !== '') && (obj.general.description !== undefined)) {
        return false
    }
    if ((obj.general.specialisation !== '') && (obj.general.specialisation !== undefined)) {
        return false
    }
    if ((obj.education[0].title !== '') && (obj.education[0].title !== undefined)) {
        return false
    }
    if ((obj.education[0].description !== '') && (obj.education[0].description !== undefined)) {
        return false
    }
    if ((obj.education[0].start !== '') && (obj.education[0].start !== undefined)) {
        return false
    }
    if ((obj.education[0].end !== '') && (obj.education[0].end !== undefined)) {
        return false
    }
    if ((obj.experience[0].description !== '') && (obj.experience[0].description !== undefined)) {
        return false
    }
    if ((obj.experience[0].company !== '') && (obj.experience[0].company !== undefined)) {
        return false
    }
    if ((obj.experience[0].title !== '') && (obj.experience[0].title !== undefined)) {
        return false
    }
    if ((obj.experience[0].start !== '') && (obj.experience[0].start !== undefined)) {
        return false
    }
    if ((obj.experience[0].end !== '') && (obj.experience[0].end !== undefined)) {
        return false
    }
    if (obj.experience[0].skills.length !== 0) {
        return false
    }
    return true
}
