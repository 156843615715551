import React, {useEffect, useState} from "react";
import ShortCvItem from "../../pages/ShortCvItem";
import {connect} from "react-redux";
import {Preloader} from "../../assets/Preloader/Preloader";
import {
    selectorCurrentCv,
    selectorFetchPaginator,
    selectorFilterCv,
    selectorIsLoading, selectorIsNewCv, selectorOffsetPaginator, selectorResponseError,
    selectorResponseSuccess,
    selectorShortCv, selectorTotalCvsCount
} from "../../redux/cv/selectors";
import {CvType, ListCvType} from "../../common/types";
import {
    addFetchPaginator,
    changeOffsetPaginator,
    clearShortCv, createNewCv,
    setFilteredShortCv,
    setShortCv
} from "../../redux/cv/actions";
import {AppStateType} from "../../redux/store";
import {Redirect} from "react-router-dom";
import {ListCvHeader} from "../Headers/ListCvHeader";
import styles from "../Main/Main.module.scss";
import loader from "../../assets/images/treeDotsLoader.svg";
import {initialData} from "../../common/formCvConstants";
import {EmptyListCv} from "../../pages/EmptyListCv";
import {NothingFound} from "../../pages/NothingFound";
import {selectorIsLogin} from "../../redux/login/selectors";
import {ErrorShowComponent} from "../ErrorShowComponent/ErrorShowComponent";


const ListCvC = ({
                     isLoading,
                     shortCv,
                     setShortCv,
                     responseSuccess,
                     filter,
                     totalCvsCount,
                     changeOffsetPaginator,
                     offsetPaginator,
                     fetchPaginator,
                     addFetchPaginator,
                     isNewCv,
                     currentCv,
                     createNewCv,
                     isLogin,
                     responseError
                 }: ListCvType): JSX.Element => {

    const [firstLoad, setFirstLoad] = useState(true)

    let token = localStorage.getItem(`token`)

    useEffect(() => {
        if (!isLoading && responseSuccess) {
            setFirstLoad(false)
        }
    }, [isLoading])

    useEffect(() => {
        if (fetchPaginator) {
            setShortCv(offsetPaginator, filter)
            changeOffsetPaginator(offsetPaginator + 5)
        }
    }, [fetchPaginator])

    useEffect(() => {
        const element = document.getElementById("scrollArea");
        element?.addEventListener('scroll', scrollHandler)
        return function () {
            element?.removeEventListener('scroll', scrollHandler)
        }
    }, [fetchPaginator])

    if (!isLogin && (token === '')) {
        return <Redirect to={'/login/'}/>
    }

    if (Object.keys(responseError).length !== 0) {
        return <ErrorShowComponent errorText={responseError}/>
    }

    const newCv = async () => {
        let newData = initialData()
        createNewCv(newData, newData.pk)
    };

    if (isNewCv) {
        return <Redirect to={`/edit-cv/${currentCv.pk}`}/>
    }

    const scrollHandler = (e: any) => {
        if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 &&
            totalCvsCount > shortCv.length) {
            addFetchPaginator()
        }
    }

    const shortCvRender = (shortCv: Array<CvType>) => {
        if (shortCv.length === 0 && filter.length !== 0) {
            return <NothingFound/>
        } else if (shortCv.length !== 0 && !firstLoad) {
            return shortCv.map(e => <ShortCvItem shortCvItem={e} key={e.pk}/>)
        } else if (!isLoading && !firstLoad) return <EmptyListCv newCv={newCv}/>
    }

    const endPageLoader = () => {
        if (isLoading && offsetPaginator && (shortCv.length !== 0)) {
            return <img src={loader} alt="loading" className={styles.loaderInDownPage}/>
        }
    }

    return (
        <div className={styles.flexContainer}>
            <ListCvHeader/>
            <section id={'scrollArea'}>
                {(isLoading && firstLoad) ?
                    <Preloader/> :
                    shortCvRender(shortCv)
                }
                <div className={styles.loaderContainer}>
                    {endPageLoader()}
                </div>
            </section>
        </div>
    )
}

export const ListCv = connect(
    (state: AppStateType) => ({
        isLoading: selectorIsLoading(state),
        shortCv: selectorShortCv(state),
        responseSuccess: selectorResponseSuccess(state),
        filter: selectorFilterCv(state),
        fetchPaginator: selectorFetchPaginator(state),
        offsetPaginator: selectorOffsetPaginator(state),
        totalCvsCount: selectorTotalCvsCount(state),
        isNewCv: selectorIsNewCv(state),
        currentCv: selectorCurrentCv(state),
        isLogin: selectorIsLogin(state),
        responseError: selectorResponseError(state),
    }), {
        setShortCv,
        setFilteredShortCv,
        addFetchPaginator,
        changeOffsetPaginator,
        clearShortCv,
        createNewCv
    })
(ListCvC);
