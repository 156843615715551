import React from "react";
import {FormType} from "../../common/types";
import styles from "./CvForm.module.scss"
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {Experience} from "./Experience";
import {Education} from "./Education";
import TextArea from "../TextArea/TextArea";
import {ImageCrop} from "../ImageCrop/ImageCrop";
import {LanguageForm} from "./componentsForm/LanguageForm";
import {SkillsForm} from "./componentsForm/SkillsForm";
import Input2 from "../Input/Input";
import {PlusCircleIcon} from "@heroicons/react/outline";

export const CvForm = ({
                           initialValue,
                           onSubmitEditedForm
                       }: FormType): JSX.Element => {
    return (
        <Form
            onSubmit={onSubmitEditedForm}
            mutators={{
                ...arrayMutators
            }}

            initialValues={initialValue.document}
            render={({
                         values,
                         submitting,
                         validating,
                         handleSubmit,
                         form: {
                             mutators: {push, pop}
                         },
                     }
            ) => (
                <form onSubmit={handleSubmit}
                      id="cvForm">
                    <div className={styles.formWrapper}>
                        <div>
                            {/*<div>{JSON.stringify(values)}</div>*/}
                            <Field name="general.avatar"
                                   component="input"
                                   type="text">
                                {props => (
                                    <ImageCrop/>
                                )}
                            </Field>
                            <Field name="general.name"
                                   component="input"
                                   type="text"
                            >
                                {({input, meta}) => (
                                    <Input2
                                        placeholder={"Name"}
                                        input={input}
                                        meta={meta}
                                        type={"text"}
                                    />
                                )}
                            </Field>

                            <Field name="general.specialisation"
                                   component="input"
                                   type="text"
                            >
                                {({input, meta}) => (
                                    <Input2
                                        placeholder={"Specialisation"}
                                        input={input}
                                        meta={meta}
                                        type={"text"}
                                    />
                                )}
                            </Field>
                            <Field name="general.description"
                                   component="textarea"
                                   type="text"
                            >
                                {props => (
                                    <TextArea
                                        placeholder={"Description"}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                    />
                                )}
                            </Field>
                            <div>
                                <Field name="general.languages"
                                       type={"text"}
                                >
                                    {({input, meta}) => (
                                        <LanguageForm input={input}
                                                      meta={meta}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div>
                                <h2>Skills</h2>
                                <Field name="skills"

                                >
                                    {({input, meta}) => (
                                        <SkillsForm input={input}
                                                    meta={meta}
                                        />
                                    )}
                                </Field>

                            </div>
                        </div>
                        <h2>Education</h2>
                        <div className={styles.fieldsWrapper}>
                            <div className={styles.fieldsContainer}>
                                <FieldArray name="education">
                                    {({fields}) =>
                                        fields.map((name, index) => (
                                            <Education name={name}
                                                       index={index}
                                                       fields={fields}
                                                       key={index}/>
                                        ))
                                    }
                                </FieldArray>
                            </div>
                            <div className="buttons">
                                <button
                                    type="button"
                                    onClick={() => push('education', undefined)}
                                    className={styles.addItemButton}
                                >
                                    <PlusCircleIcon className={styles.smallIcon}/>
                                    <div>
                                        Add Education
                                    </div>
                                </button>
                            </div>
                        </div>
                        <h2>Experience</h2>
                        <div className={styles.fieldsWrapper}>
                            <div className={styles.fieldsContainer}>
                                <FieldArray name="experience">
                                    {({fields}) =>
                                        fields.map((name, index) => (
                                            <Experience name={name}
                                                        index={index}
                                                        fields={fields}
                                                        key={index}

                                            />
                                        ))
                                    }
                                </FieldArray>
                            </div>
                            <div className="buttons">
                                <button
                                    type="button"
                                    onClick={() => push('experience', undefined)}
                                    className={styles.addItemButton}
                                >
                                    <PlusCircleIcon className={styles.smallIcon}/>
                                    <div>
                                        Add Experience

                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </form>
            )}
        />

    )
}

