import React, {ChangeEventHandler} from "react";
import styles from "./TextArea.module.scss"
import TextareaAutosize from 'react-textarea-autosize';


type InputType = {
    placeholder: string
    value: string
    onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined
}

const TextArea = ({placeholder, value, onChange}: InputType): JSX.Element => {

    return (
        <div className={styles.textareaContainer}>
            <TextareaAutosize
                placeholder={placeholder}
                minRows={4}
                maxRows={12}
                      onChange={onChange}
                      value={value}
                      required/>
            <div className={value ?
                `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                styles.upperPlaceholder
            }>
                {placeholder}
            </div>
        </div>
    )
}

export default TextArea