import React from 'react';
import styles from './DeleteCvModal.module.scss';
import {Redirect} from "react-router-dom";
import {XIcon, TrashIcon} from "@heroicons/react/outline";

function DeleteCvModal({closeModal, confirmDelete, isSuccessDelete}:
                      {closeModal: ()=>void, confirmDelete:  ()=>void, isSuccessDelete: boolean}) {

    if (isSuccessDelete) return <Redirect to={`/cv-list`}/>

    return (
            <div className={styles.modalReport}>
                <XIcon className={styles.cancelCross}
                       onClick={() => closeModal()}/>
                <div>
                    <TrashIcon className={styles.bigIcon}/>
                </div>
                <div className={styles.redText}>ARE YOU SURE?</div>
                <div className={styles.attention}>
                    This action will delete your CV
                </div>
                <button className={styles.alertButton}
                onClick={()=>confirmDelete()}>
                    <TrashIcon className={styles.smallIcon}/>
                    <span>Delete my CV</span>
                </button>
                <button className={styles.cancelButton}
                        onClick={()=> closeModal()}>Not delete
                </button>
            </div>
    )
}

export default DeleteCvModal