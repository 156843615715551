import React from "react";
import styles from "./Button.module.scss"


type ButtonType = {
    onClickAction: (value: any) => void
    buttonPict?: string
    buttonText: string
    buttonStyle: string
    isDisabled?: boolean
}


export const Button = ({
                           buttonPict,
                           buttonText,
                           onClickAction,
                           buttonStyle,
                           isDisabled
                       }: ButtonType) => {

    let styleOfButton = () => {
        switch (buttonStyle) {
            case 'blueButton':
                return `${styles.defaultButton} ${styles.blueButton}`;
            case 'whiteButton':
                return `${styles.defaultButton} ${styles.whiteButton}`;
            default:
                return `${styles.defaultButton}`

        }
    }

    return (
        <button className={styleOfButton()}
                onClick={onClickAction}

                disabled={isDisabled !== undefined ? isDisabled : false}
        >
            {
                buttonPict
                    ? <img src={buttonPict} alt={`${buttonPict}Pict`}/>
                    : null
            }
            {buttonText}
        </button>
    )
}