import React, {useEffect, useState} from "react";
import {SkillComponentType} from "../../../common/types";
import styles from "./CustomFields.module.scss";
import resetFilterIcon from "../../../assets/images/resetFilterIcon-01.svg";
import {connect} from "react-redux";
import {AppStateType} from "../../../redux/store";
import {
    selectorFoundSkills,
    selectorIsLoadingSkills,
} from "../../../redux/cv/selectors";
import {getSkills} from "../../../redux/cv/actions";
import loader from "../../../assets/images/treeDotsLoader.svg";

export const SkillsFormC = ({
                                input,
                                meta,
                                foundSkills,
                                getSkills,
                                isLoadingSkills
                            }: SkillComponentType): JSX.Element => {

    const initialSkills = () => {
        if (!input.value.length) {
            return []
        } else return input.value
    }

    const [typeValue, setTypeValue] = useState('');
    const [receivedSkills, setReceivedSkills] = useState([] as Array<string>);
    const [confirmedSkills, setConfirmedSkills] = useState(initialSkills);
    const [showDropDown, setShowDropDown] = useState(false);

    useEffect(() => {
        input.onChange(confirmedSkills);
    }, [confirmedSkills]);

    useEffect(() => {
        setReceivedSkills(foundSkills);
    }, [foundSkills]);

    const loadOptions = async (value: string) => {
        getSkills(value)
    }


    const onTypeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTypeValue(e.target.value);
        if (e.target.value.length >= 1) {
            setShowDropDown(true)
            // @ts-ignore
            loadOptions(e.target.value).then(r => setReceivedSkills(r))
        } else {
            setReceivedSkills([])
            setShowDropDown(false)
        }
    }

    const addSkill = (option: string) => {
        const checkRepeatValue = (arg: string) => {
            return arg !== option
        }
        if (confirmedSkills.every(checkRepeatValue)) {
            const newArr = [...confirmedSkills]
            newArr.push(option)
            setConfirmedSkills(newArr)
            setTypeValue('')
            setShowDropDown(false)
        }
    }

    const deleteSkill = (option: string) => {
        let newArr = confirmedSkills.filter((e: any) => e !== option)
        setConfirmedSkills(newArr)
    }

    const checkRepeat = () => {
        return receivedSkills.filter(e => confirmedSkills.indexOf(e) < 0)
    }

    const ReceivedSkills = (receivedSkills: string[]) => {
        if (!receivedSkills.length) {
            return (
                <div className={styles.selectingItems}
                     onClick={() => addSkill(typeValue)}>add {typeValue}</div>
            )
        } else {

            return (
                receivedSkills.map(option => <div
                        key={option}
                        className={styles.selectingItems}
                        tabIndex={-1}
                        aria-disabled={false}
                        onClick={() => addSkill(option)}
                    >
                        {option}
                    </div>
                )
            )
        }
    }

    const ConfirmedSkills = (confirmedSkills: string[]) => {
        return (
            confirmedSkills.map(name => <div
                    key={name}
                    className={styles.skillBox}
                    tabIndex={-1}
                    aria-disabled={false}
                > {name}
                    <img
                        onClick={() => deleteSkill(name)}
                        src={resetFilterIcon}
                        alt={'cross'}
                        className={styles.resetIcon}/>
                </div>
            )
        )
    }

    return (
        <div>
            <div className={meta.error && meta.touched ?
                `${styles.inputContainerSelect} ${styles.inputValidate}` :
                styles.inputContainerSelect}>
                {ConfirmedSkills(confirmedSkills)}
                <div className={styles.skillsContainer}>
                    <input placeholder={"Search by Skills"}
                           {...input}
                           onChange={onTypeValueChange}
                           value={typeValue}
                           className={styles.inputInSelect}
                           onKeyDown={(key) => {
                               if (key.code === "Enter" || key.code === "NumpadEnter") {
                                   addSkill(typeValue)
                               }
                           }}/>
                    <div className={typeValue ?
                        `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                        styles.upperPlaceholder
                    }>
                        {meta.error && meta.touched ? null : "Search by Skills"}
                    </div>
                    {meta.error && meta.touched && <div className={styles.validation}>{meta.error}</div>}
                </div>
            </div>
            <div className={styles.wrapReceived}>
                {showDropDown ?
                    <div className={styles.dropDownWindow}>
                        {isLoadingSkills ?
                            <img src={loader} alt="save" className={styles.smallIcon}/>
                            :
                            ReceivedSkills(checkRepeat())
                        }
                    </div> :
                    <></>
                }
            </div>
        </div>
    )
}

export const SkillsForm =
    connect(
        (state: AppStateType) => ({
            foundSkills: selectorFoundSkills(state),
            isLoadingSkills: selectorIsLoadingSkills(state),
        }), {getSkills}
    )(SkillsFormC)