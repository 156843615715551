import axios from "axios";
import fileDownload from 'js-file-download'
import {CvType} from "../common/types";

const backendApiDomain = `${process.env.REACT_APP_BACKEND_API_DOMAIN}`;

const instance = axios.create(
    {
        withCredentials: true,
        baseURL: backendApiDomain
    }
)

instance.interceptors.request.use((config) => {

    // @ts-ignore
    config.headers.authorization = `Bearer ${localStorage.getItem(`token`)}`
    return config
})

export const cvAPI = {
    getListCv(offset: number, filter?: string) {
        let setFilter = filter
            ? `/cv/?limit=5&offset=${offset}&search=${filter}`
            : `/cv/?limit=5&offset=${offset}`
        return instance.get(`${setFilter}`)
            .then(response => {
               return  response
            })
            .catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    getCurrentCv(id: string) {
        return instance.get(`/cv/${id}/`)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    updateCv(editedCv: CvType, id: string) {
        return instance.put(`/cv/${id}/`, editedCv)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    postAvatar(avatar: any, id: string) {
        const formData = new FormData()
        formData.append('file', avatar)
        return instance.put(`/cv/${id}/avatar/`, formData)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    deleteAvatar(id: string) {
        return instance.delete(`/cv/${id}/avatar/`)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    deleteCv(id: string) {
        return instance.delete(`/cv/${id}/`)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },

    getSkill(skillsName: string) {
        return instance.get(`/skill/?search=${skillsName}`)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },


    getPdf(id: string, name: string) {
        return instance.get(`/cv/${id}/pdf/`, {responseType: 'blob'})
            .then(response => {
                fileDownload(response.data, `DefaultCv-${name}.pdf`)
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },


    getPublicCv(id: string) {
        return instance.post(`/cv/${id}/public/`)
            .then(response => {
                return response
            }).catch(function (error) {
                if (error.response) {
                    return (error.response);
                } else {
                    return (error.message);
                }
            });
    },
}

export const showPublicCv = (id: string, secret: string) => {
    return axios.get(`${backendApiDomain}public/cv/${id}/?secret=${secret}`)
        .then(response => {
            return response
        }).catch(function (error) {
            if (error.response) {
                return (error.response);
            } else {
                return (error.message);
            }
        });
}

export const getPublicPdf = (id: string, name: string, secret: string) => {
    return axios.get(`${backendApiDomain}public/cv/${id}/pdf/?secret=${secret}`,
        {responseType: 'blob'} )
        .then(response => {
            fileDownload(response.data, `DefaultCv-${name}.pdf`)
            return response
        }).catch(function (error) {
            if (error.response) {
                return (error.response);
            } else {
                return (error.message);
            }
        });
}

