import React from "react";
import styles from "./Header.module.scss";
import defaultCvLogo from "../../../assets/images/defaultCvLogoBlack_2-01.svg";
import pdfIcon from "../../../assets/images/pdf_blue.svg";
import {NavLink} from "react-router-dom";
import {HeaderPublicCvType} from "../../../common/types";

export const HeaderPublicCv = ({getPublicCvPdf, currentCv}: HeaderPublicCvType):JSX.Element => {

    return (
        <header className={styles.headerContainer}>
            <NavLink to={`/`}>
                    <img src={defaultCvLogo} alt="back" className={styles.logo}/>
            </NavLink>
            <div className={styles.filtersBar}>
                <div className={styles.filters}
                     onClick={()=> getPublicCvPdf(currentCv.pk, currentCv.document.general.name, currentCv.secret)}>
                    <img src={pdfIcon} alt="pdf"/>
                    <span>Download</span>
                </div>
            </div>
        </header>
    )
}
