import React, {useEffect, useState} from "react";
import styles from "./Input.module.scss"
import parseISO from 'date-fns/parseISO'
import moment from 'moment';
import DatePicker from "react-datepicker";
import {InputDataType2} from "../../common/types";

const InputStartData = ({placeholder,
                            input,
                            meta, type,
                            startDate,
                            endDate,
                            setStartDate
}: InputDataType2): JSX.Element => {

    interface IDate {
       value: null | Date ;
    }

    const [inputData, setInputData] = useState<IDate>({value: null});
    const [isConverted, setIsConverted] = useState(false);

    useEffect(()=>{
        if (input.value && !isConverted) {
            if (input.value.match(/^[a-zA-Z]{3}\s\d{2}\s\d{4}$/)) {
                let convertDate = moment(new Date(input.value)).format()
                setInputData({value: parseISO(convertDate)})
                setIsConverted(true)

            } else {
                input.onChange('')
            }
        }
    }, [input, isConverted])

    useEffect(() => {
        if (inputData.value === null) {
            input.onChange('')
        } else {
            let dateFormat = "MMM DD YYYY"
            input.onChange(moment(inputData.value, dateFormat).format('MMM DD YYYY'))
            setStartDate(inputData.value)
        }
    }, [inputData])

    //css DataPicker in App.css
    return (
        <div className={styles.inputContainer}>
            <DatePicker
                selected={inputData.value}
                onChange={(date) => setInputData({value: date})}
                selectsStart
                maxDate={endDate}
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMM yyyy"
                showMonthYearPicker
                placeholderText="month / year"
                previousYearButtonLabel={'<'}
                nextYearButtonLabel={'>'}
            />
            <div className={input.value ?
                `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                styles.upperPlaceholder
            }>
                {placeholder}

            </div>
        </div>
    );
}

export default InputStartData