import React from "react";
import {LanguagesType} from "../../common/types";
import styles from "./Language.module.scss";

const Language: React.FC<LanguagesType> = (languages) => {

    let languagesRender = languages.languages.map(m =>
            <div className={styles.languageBox}
                 key = {m.title}>
                <span className={styles.languageName}>{m.title}</span>
                <span className={styles.languageIndex}>{m.level}</span>
            </div>
    )
    return (
        <div>
            {languagesRender}
        </div>
    )
}

export default Language;