import React, {useEffect, useRef, useState} from "react";
import styles from "./Header.module.scss";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import shareIcon from "../../../assets/images/share_native.svg";
import switcherOff from "../../../assets/images/switcher_off.svg";
import switcherOn from "../../../assets/images/switcher_on.svg";
import editIcon from "../../../assets/images/editIcon.svg";
import deleteIcon from "../../../assets/images/TrashIconRed.svg";
import pdfIcon from "../../../assets/images/pdf_blue.svg";
import {NavLink} from "react-router-dom";
import {HeaderCurrentCvType} from "../../../common/types";
import Modal from "react-modal";
import ReactModal from "react-modal";
import DeleteCvModal from "../../Modals/DeleteCvModal/DeleteCvModal";
import {UserInHeader} from "../UserInHeader";
import {ChevronDownIcon, ChevronUpIcon, MenuIcon} from "@heroicons/react/outline";
import {ShareCvModal} from "../../Modals/ShareCvModal/ShareCvModal";


Modal.setAppElement('#root');

export const HeaderCurrentCv = ({    getLinkPublicCvAction,
                                     currentCv, postCvForm, deleteCv,
                                     isSuccessDelete, isLoading, getCvPdf}:
                                     HeaderCurrentCvType): JSX.Element => {


    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);


    const rootEl = useRef(null);


    function openModal(whichOne: string) {
        if (whichOne === 'share') {
            setModalShareIsOpen(true);
        } else {
            setModalDeleteIsOpen(true)
        }
    }

    function closeModal() {
        setModalShareIsOpen(false);
        setModalDeleteIsOpen(false);
    }

    const confirmDelete = async () => {
        deleteCv(currentCv.pk)
    }

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);


    const handleClick = (e: any) => {
        // @ts-ignore
        if (rootEl.current && !rootEl.current.contains(e.target)) {
            return setIsOpenOptions(false)
        }
    }

    const changeActiveMode = async () => {
        currentCv = {...currentCv, is_active: !currentCv.is_active}
        postCvForm(currentCv, currentCv.pk)
    };

    const lastUpdateConverter = () => {
        if (currentCv.pk) {
            return currentCv.updated.substring(0, 10)
        } else {
            return null
        }
    }

    return (
        <header className={styles.headerContainer}>
            <div className={styles.leftBlock}>
                <NavLink to={`/cv-list`}>
                    <div className={styles.buttonBack}>
                        <img src={arrowLeft} alt="back"/>
                    </div>
                </NavLink>
                <div className={styles.lastUpdate}>
                    {isLoading ? <></> :
                        <span>last update {lastUpdateConverter()}</span>
                    }
                </div>
            </div>

            <div className={styles.filtersBar}>
                <div className={styles.filtersBar}>
                    <div className={styles.filters}
                    onClick={()=> getCvPdf(currentCv.pk, currentCv.document.general.name)}>
                        <img src={pdfIcon} alt="pdf"/>
                        <span>Download</span>
                    </div>
                    <button
                        onClick={() => openModal('share')}
                        className={styles.filters}>
                        <img src={shareIcon} alt="share"/>
                        <span>Share</span>
                    </button>


                    <div ref={rootEl} className={isOpenOptions ?
                        `${styles.optionsWrapper} ${styles.optionsWrapperOpen}` :
                        styles.optionsWrapper
                    }>
                        <div className={isOpenOptions ? styles.optionActive :
                            styles.optionIsActive
                        }
                             onClick={() => {
                                 setIsOpenOptions(!isOpenOptions)
                             }}>

                            <MenuIcon className={
                                isOpenOptions ?`${styles.smallIcon} ${styles.optionGray}`:
                                    styles.smallIcon
                            }/>
                            <span className={
                                isOpenOptions ?
                                    styles.optionGray :
                                    ''
                            }>Options</span>
                            {
                                isOpenOptions ?
                                    <ChevronUpIcon className={`${styles.chevron} ${styles.optionGray}`}/> :
                                    <ChevronDownIcon className={styles.chevron}/>
                            }
                        </div>
                        <div className={isOpenOptions ? '' : styles.hide}>
                            <div>
                                <NavLink to={`/edit-cv/${currentCv.pk}`} className={styles.optionSelect}>
                                    <img src={editIcon} alt="pdf"/>
                                    <span>Edit</span>
                                </NavLink>
                                <div className={styles.optionSelect}
                                     onClick={() => openModal('delete')}>
                                    <img src={deleteIcon} alt="delete"/>
                                    <span className={styles.optionDelete}>Delete</span>
                                </div>

                            </div>
                            <div className={`${styles.optionSelect} ${styles.optionSelectSwitch}`}
                                 onClick={() => changeActiveMode()}>
                                    <div className={currentCv.is_active ? styles.activate : styles.desActive }>
                                        <img src={currentCv.is_active ? switcherOn : switcherOff } alt="switch"/>
                                        <span>Active</span>
                                    </div>

                            </div>
                        </div>

                    </div>


                </div>
                <ReactModal isOpen={modalShareIsOpen}
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                            className="ShareModal"
                            closeTimeoutMS={200}
                >
                    <ShareCvModal currentCv={currentCv}
                                  getLinkPublicCvAction={getLinkPublicCvAction}
                                  isLoading={isLoading}
                                  closeModal={closeModal}

                    />
                </ReactModal>
                <ReactModal isOpen={modalDeleteIsOpen}
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                            className="ShareModal"
                            closeTimeoutMS={200}
                >
                    <DeleteCvModal confirmDelete={confirmDelete}
                                   closeModal={closeModal}
                                   isSuccessDelete={isSuccessDelete}
                    />
                </ReactModal>
                <UserInHeader/>
            </div>
        </header>
    )
}
