import {useGoogleLogin} from 'react-google-login'
import {refreshTokenSetup} from "./refreshTokenSetup";


function HandleLogin (logInGoogle) {

    const onSuccess = (res) => {
        logInGoogle(res)
        refreshTokenSetup(res)
    }

    const onFailure = (res) => {
        console.log('loginerror', res)
    }

    const {loaded} = useGoogleLogin({
        onSuccess: onSuccess,
        onFailure: onFailure,
        clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
        isSignedIn: true,
        accessType: 'offline'
    })

    return loaded
}

export default HandleLogin