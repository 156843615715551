import React, {useEffect, useState} from "react";
import styles from "./Authorization.module.scss"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";
import checkEmail from "../../assets/images/checkEmail.png";
import {Redirect} from "react-router-dom";

export const CheckEmail = () => {

    const [toSignIn, setToSignIn] = useState(false);

    if (toSignIn) {
        return <Redirect to='/test/authorization/'/>
    }

    return (
        <div className={styles.container}>
            <div className={styles.authWindow}>
                <div className={styles.header}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h1>Check your email</h1>
                </div>
                <img src={checkEmail}
                     alt="check email"
                className={styles.checkEmail}/>
                <div className={styles.notation}>
                    <div>We sent a confirmation email to:</div>
                    <div className={styles.emailPresent}>Example@email.com</div>
                    <div>Check your email and click on the</div>
                    <div>confirmation link to continue.</div>
                </div>

                <div className={styles.footer}>
                    <div>Have an account?</div>
                    <a onClick={() => setToSignIn(true)}>Sign in</a>
                </div>
            </div>
        </div>
    )
}