import React from "react";
import styles from "./Sidebar.module.scss"
import logoWhite from "../../assets/images/defaultCvLogoWhite_2-01.svg"
import {LogoutButton} from "../LogoutButton/LogoutButton";
import {connect} from "react-redux";
import {AppStateType} from "../../redux/store";
import {createNewCv} from "../../redux/cv/actions";
import {SideBarType} from "../../common/types";
import {initialData} from "../../common/formCvConstants";
import {selectorIsSaving} from "../../redux/cv/selectors";
import {selectorIsLogin, selectorLoginUser} from "../../redux/login/selectors";
import defaultAvatar from "../../assets/images/defaultUserPicture.svg";

const SidebarC = ({isSaving, loginUser, createNewCv, isLogin}: SideBarType
): JSX.Element => {

    const onErrorAvatar = (e: any) => {
        e.target.src = defaultAvatar
    }
    const newCv = async () => {
        let newData = initialData()
        createNewCv(newData, newData.pk)
    };

    return (
        isLogin
            ? <div className={styles.sideBarContainer}>
                <img src={logoWhite} alt="" className={styles.logo}/>
                <div className={styles.loggedUser}>
                    <div className={styles.loggedUserImageContainer}>
                        <div className={styles.firstRing}>
                            <div className={styles.secondRing}>
                                <div className={styles.loggedUserImageNoImage}>
                                    <img src={defaultAvatar} alt=""/>
                                    <img src={loginUser.imageUrl} alt="" onError={onErrorAvatar}
                                         className={styles.loggedUserImage}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={styles.loggedUserFirstName}>{loginUser.givenName}</div>
                    <div className={styles.loggedUserLastName}>{loginUser.familyName}</div>
                    <div className={styles.loggedUserMail}>{loginUser.email}</div>
                    <button className={styles.buttonAddCv}
                            disabled={isSaving}
                            onClick={() => newCv()}>
                        <div>Add CV</div>
                    </button>

                </div>
                <LogoutButton/>
            </div>
            : <></>
    )
}

export const Sidebar = connect(
    (state: AppStateType) => ({
        loginUser: selectorLoginUser(state),
        isSaving: selectorIsSaving(state),
        isLogin: selectorIsLogin(state)
    }), {createNewCv}
)(SidebarC)