import React from "react";
import styles from "./Skills.module.scss";


const Skills = ({skill, index, id}: { skill: string, index: number, id?: string }): JSX.Element => {

    return (
        <div
            id={`div${index}${id}`}
            className={styles.skillElement}
            key={index}>
            <span className={styles.elementName}>{skill}</span>
        </div>
    )
}

export default Skills;