import React from "react";
import {AppStateType} from "../../redux/store";
import styles from "./Login.module.scss"
import googleIcon from "../../assets/images/googleIcon.svg"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg"
import {Redirect} from "react-router-dom";
import GoogleLogin from "react-google-login";
import {connect} from "react-redux";
import {logInGoogle} from "../../redux/login/actions";
import {selectorErrorLoginResponse, selectorIsLogin, selectorIsLoginError} from "../../redux/login/selectors";
import {selectorIsLoading} from "../../redux/cv/selectors";
import {Preloader} from "../../assets/Preloader/Preloader";
import {ErrorShowComponent} from "../ErrorShowComponent/ErrorShowComponent";
import {refreshTokenSetup} from "../../auth/refreshTokenSetup";

type LoginType = {
    logInGoogle: (value: any) => void
    isLogin: boolean
    isLoading: boolean
    errorLoginResponse: string
    isLoginError: boolean
}

export const LoginC = ({logInGoogle, isLogin, isLoading, isLoginError, errorLoginResponse}: LoginType) => {

    if (isLogin) return <Redirect to="/cv-list"/>

    if (isLoginError) {
        return <ErrorShowComponent errorLoginText={errorLoginResponse}/>
    }

    const onSuccess = (res: any) => {
        logInGoogle(res)
        refreshTokenSetup(res)
    }

    const onFailure = (res: any) => {
        console.log('loginerror', res)
    }

    return (
        <div className={styles.container}>
            {isLoading
                ? <Preloader/>
                : <div className={styles.signWindow}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h4>Welcome!</h4>
                    <div>
                        <span>Please sign in</span>
                    </div>

                    <GoogleLogin
                        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                        render={renderProps => (
                            <button onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    className={styles.loginButton}>
                                <img src={googleIcon} alt="logo Google"/>
                                Sign in with Google
                            </button>
                        )}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        isSignedIn={true}
                    />
                </div>
            }
        </div>
    )
}

export const Login = connect(
    (state: AppStateType) => ({
        isLoginError: selectorIsLoginError(state),
        errorLoginResponse: selectorErrorLoginResponse(state),
        isLogin: selectorIsLogin(state),
        isLoading: selectorIsLoading(state),
    }),
    {logInGoogle}
)(LoginC)