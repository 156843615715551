import React from "react";
import styles from "../components/Main/Main.module.scss";
import {SearchIcon} from "@heroicons/react/outline";


export const NothingFound = () => {
    return (
        <div className={styles.notFound}>
            <SearchIcon className={styles.notFound__icon}/>
             <div>Nothing found</div>
        </div>
    )
}