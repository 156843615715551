import React, {useEffect, useState} from "react";
import {LanguageComponentType, LanguageType} from "../../../common/types";
import {languagesData, languagesLevel} from "../../../common/languagesData";
import styles from "./CustomFields.module.scss";
import resetFilterIcon from "../../../assets/images/resetFilterIcon-01.svg";


export const LanguageForm = ({    input,
                                  meta
                              }: LanguageComponentType): JSX.Element => {

    const [typeValue, setTypeValue] = useState('');
    const [receivedLanguages, setReceivedLanguages] = useState([]);
    const [confirmedLanguage, setConfirmedLanguage] = useState('');
    const [completeLanguages, setCompleteLanguages] = useState(input.value);
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDownLevel, setShowDropDownLevel] = useState(false);

    useEffect(() => {
        input.onChange(completeLanguages);
    },  [completeLanguages]);

    const onTypeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTypeValue(e.target.value);
        if (e.target.value.length >= 2) {
            setShowDropDown(true)
            let recLanguages: any = languagesData.filter(x =>
                x.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
            setReceivedLanguages(recLanguages)
        } else {
            setReceivedLanguages([])
            setShowDropDown(false)
        }
    }

    const addLanguage = (option: string) => {
        setConfirmedLanguage(option)
        setTypeValue('')
        setShowDropDown(false)
        setShowDropDownLevel(true)
    }

    const addCompeteLanguage = (confirmedLanguage: string, level: string) => {
        let newCompleteLanguage = {
            title: confirmedLanguage,
            level: level
        }
        setShowDropDownLevel(false)
        if (completeLanguages.length) {
            const newArr = [...completeLanguages]
            newArr.push(newCompleteLanguage)
            setCompleteLanguages(newArr)
            setTypeValue('')
            setConfirmedLanguage('')
            input.onChange(completeLanguages)
        } else {
            setCompleteLanguages([newCompleteLanguage])
            input.onChange(completeLanguages)
        }
    }

    const deleteLanguage = (option: string) => {
        let newArr = completeLanguages.filter((e: any) => e.title !== option)
        setCompleteLanguages(newArr)
    }

    const checkRepeat = () => {
        return receivedLanguages.filter(e => confirmedLanguage.indexOf(e) < 0)
    }

    const ReceivedLanguages = (receivedLanguages: string[]) => {

        if (receivedLanguages.length === 0) {
            return "not found"
        }

        return (
            receivedLanguages.map(option => <div
                    key={option}
                    className={styles.selectingItems}
                    tabIndex={-1}
                    aria-disabled={false}
                    onClick={() => addLanguage(option)}>
                    {option}
                </div>
            )
        )
    }

    const LevelLanguage = () => {
        return (
            languagesLevel.map(option => <div
                    key={option}
                    className={styles.selectingItems}
                    tabIndex={-1}
                    aria-disabled={false}
                    onClick={() => addCompeteLanguage(confirmedLanguage, option)}>
                    {option}
                </div>
            )
        )
    }

    const CompleteLanguages = (completeLanguages: LanguageType[]) => {
        if (completeLanguages.length) {
            return (
                completeLanguages.map(name => <div
                        key={name.title}
                        className={styles.skillBox}
                        tabIndex={-1}
                        aria-disabled={false}
                    > {name.title}
                        <div className={styles.languageLevel}> : {name.level}</div>
                        <img
                            onClick={() => deleteLanguage(name.title)}
                            src={resetFilterIcon}
                            alt={'cross'}
                            className={styles.resetIcon}/>
                    </div>
                )
            )
        } else <></>
    }

    return (
        <div>
            <div className={ meta?.error && meta.touched ?
                `${styles.inputContainerSelect} ${styles.inputValidate}`:
                 styles.inputContainerSelect}>
                {CompleteLanguages(completeLanguages)}
                <div className={styles.skillsContainer}>
                    <input placeholder={"Search by Language"}
                           {...input}
                           onChange={onTypeValueChange}
                           value={typeValue}
                           className={styles.inputInSelect}/>
                    <div className={typeValue ?
                        `${styles.upperPlaceholder} ${styles.upperPlaceholderShow}` :
                         styles.upperPlaceholder
                    }>
                        { meta?.error && meta.touched ? null : "Search by Language"}
                    </div>
                    {meta?.error && meta.touched && <div className={styles.validation}>{meta.error}</div>}
                </div>
            </div>
            <div className={styles.wrapReceived}>
                {showDropDown ?
                    <div className={styles.dropDownWindow}>
                        {ReceivedLanguages(checkRepeat())}
                    </div> :
                    <></>
                }
                {showDropDownLevel ?
                    <div className={styles.dropDownWindow}>
                        <span>Choose your level:</span>
                        {LevelLanguage()}
                    </div> :
                    <></>
                }
            </div>
        </div>
    )
}
