import * as constants from './constants';
import {LoginActionType} from "./actions";



const initialState = {
    isLoginProcess: false,
    isLogin: false,
    isLoginError: false,
    errorLoginResponse: '',
    loginUser: {
        email: '',
        familyName: '',
        givenName: '',
        imageUrl: '',
    }
}



type InitialStateType = typeof initialState;


export const  loginReducer = (state = initialState, action: LoginActionType): InitialStateType => {
    switch (action.type) {
        case constants.IS_LOGIN:
            return {...state, isLogin: action.isLogin, loginUser: action.loginUser,};
        case constants.IS_LOGIN_ERROR:
            return {...state, isLoginError: action.isLoginError};
        case constants.IS_LOGIN_PROCESS:
            return {...state, isLoginProcess: action.isLoginProcess};
            case constants.ERROR_LOGIN_RESPONSE:
            return {...state, errorLoginResponse: action.errorLoginResponse};
        default:
            return state;
    }
}