import React, {useEffect, useState} from "react";
import styles from "./Authorization.module.scss"
import logoDefaultCv from "../../assets/images/defaultCvLogoBlack_2-01.svg";
import {Button} from "../Button";
import eye from "../../assets/images/eye.svg";
import eye_off from "../../assets/images/eye_off.svg";
import ReCAPTCHA from "react-google-recaptcha";
import ReactModal from "react-modal";
import PolicyModal from "../Modals/PolicyModal/PolicyModal";
import {Redirect} from "react-router-dom";


export const CreateAccount = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [policy, setPolicy] = useState(false)
    const [captcha, setCaptcha] = useState(false)
    const [isShowPass, setIsShowPass] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [modalPolicyIsOpen, setModalPolicyIsOpen] = useState(false);
    const [toSignIn, setToSignIn] = useState(false);

    const closeModal = () => {
        setModalPolicyIsOpen(false);
    }

    const changeInputEmail = (data: any) => {
        setEmail(data.currentTarget.value)
    }

    const changeInputPassword = (data: any) => {
        setPassword(data.currentTarget.value)
    }
    const changePolicy = (data: any) => {
        setPolicy(data.target.checked)
    }

    const showPass = () => {
        return setIsShowPass(!isShowPass)
    }

    const emailValidate = (checkEmailData: string) => {
        const reg = /\S+@\S+\.\S+/
        return reg.test(checkEmailData)
    }

    const recaptchaOnChange = (value: any) => {
        setCaptcha(value)
    }


    useEffect(() => {
        if (emailValidate(email) && password.length && policy) {
            setIsValid(true)
        } else setIsValid(false)
    }, [email, password, policy])

    if (toSignIn) {
       return <Redirect to='/test/authorization/'/>
    }

    const loginSubmit = () => {
        const sendData = {
            'login': email,
            'password': password,
        }
        alert(JSON.stringify(sendData))
    }



    return (
        <div className={styles.container}>
            <div className={styles.authWindow}>
                <div className={styles.header}>
                    <img src={logoDefaultCv} alt="logo" className={styles.logo}/>
                    <h1>Create your account</h1>
                </div>

                <form className={styles.authForm}>
                    <input placeholder={'Email'}
                           type={'text'}
                           value={email}
                           onChange={changeInputEmail}/>
                    <input placeholder={'Password'}
                           type={isShowPass ? 'text' : 'password'}
                           value={password}
                           onChange={changeInputPassword}
                    />
                    <img src={isShowPass ? eye : eye_off}
                         onClick={() => showPass()}
                         alt="showPass"
                         className={styles.showIcon}/>

                    <div className={styles.checkLine}>
                        <input type="checkbox"
                               className={styles.checkBox}
                               id={'check'}
                               onChange={changePolicy}
                               checked={policy}
                        />
                        <span className={styles.policy}>I agree to
                            <a onClick={()=>setModalPolicyIsOpen(true)}> privacy policy</a>
                        </span>
                    </div>

                    <ReCAPTCHA
                        sitekey={'6LdwsNsfAAAAADP-Qn6lUwAuQq-TXWU4oKj82wy1'}
                        onChange={recaptchaOnChange}
                        className={styles.recaptcha}
                    />

                    <Button onClickAction={loginSubmit}
                            buttonText={'Create account'}
                            buttonStyle={'blueButton'}
                            isDisabled={!isValid}/>


                </form>

                <div className={styles.footer}>
                    <div>Have an account?</div>
                    <a onClick={() => setToSignIn(true)}>Sign in</a>
                </div>
            </div>
            <ReactModal isOpen={modalPolicyIsOpen}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={closeModal}
                        overlayClassName="Overlay"
                        className="ShareModal"
                        closeTimeoutMS={200}
            >
                <PolicyModal closeModal={closeModal}/>
            </ReactModal>
        </div>
    )
}