import {CvType, ErrorType} from "../../common/types";
import {Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {AppStateType, InferActionTypes} from "../store";
import {cvAPI, getPublicPdf, showPublicCv} from "../../api/api";
import * as constants from "./constants";

export type CurrentCvActionType = InferActionTypes<typeof actions>

const actions = {
    getCurrentCvAC: (currentCv: CvType) => ({type: constants.GET_CURRENT_CV, currentCv} as const),
    getShortCvAC: (shortCvs: Array<CvType>) => ({type: constants.GET_SHORT_CV, shortCvs} as const),
    getPublicCvAC: (secret: string) => ({type: constants.GET_PUBLIC_CV, secret} as const),
    setFilterAC: (filter: string) => ({type: constants.SET_FILTER, filter} as const),
    postedAvatarAC: (postedAvatar: string) => ({type: constants.POSTED_AVATAR, postedAvatar} as const),

    clearCurrentCvAC: () => ({type: constants.CLEAR_CURRENT_CV} as const),
    clearShortCvAC: () => ({type: constants.CLEAR_SHORT_CV} as const),
    clearResponseErrorAC: () => ({type: constants.RESPONSE_ERROR_CLEAN} as const),

    isLoadingAC: (isLoading: boolean) => ({type: constants.IS_LOADING, isLoading} as const),
    isLoadingSkillsAC: (isLoadingSkills: boolean) => ({type: constants.IS_LOADING_SKILLS, isLoadingSkills} as const),
    isLoadingAvatarAC: (isLoadingAvatar: boolean) => ({type: constants.IS_LOADING_AVATAR, isLoadingAvatar} as const),
    isSavingAC: (isSaving: boolean) => ({type: constants.IS_SAVING, isSaving} as const),
    isNewCvAC: (isNewCv: boolean) => ({type: constants.IS_NEW_CV, isNewCv} as const),

    fetchPaginatorAC: (fetchPaginator: boolean) => ({type: constants.FETCH_PAGINATOR, fetchPaginator} as const),
    offsetPaginatorAC: (offsetPaginator: number) => ({type: constants.OFFSET_PAGINATOR, offsetPaginator} as const),
    totalCvsCountAC: (totalCvsCount: number) => ({type: constants.TOTAL_CVS_COUNT, totalCvsCount} as const),

    foundSkillsAC: (foundSkills: Array<string>) => ({type: constants.FOUND_SKILLS, foundSkills} as const),
    isLoadingPdfAC: (isLoadingPdf: boolean) => ({type: constants.IS_LOADING_PDF, isLoadingPdf} as const),
    pdfErrorAC: (pdfError: string) => ({type: constants.PDF_ERROR, pdfError} as const),

    responseSuccessAC: (responseSuccess: boolean) => ({type: constants.RESPONSE_SUCCESS, responseSuccess} as const),
    responseErrorAC: (responseError: ErrorType) => ({type: constants.RESPONSE_ERROR, responseError} as const),
    responseSuccessDeleteAC: (responseSuccessDelete: boolean) => ({
        type: constants.RESPONSE_SUCCESS_DELETE,
        responseSuccessDelete
    } as const),
    responseSuccessPostAC: (responseSuccessPost: boolean) => ({
        type: constants.RESPONSE_SUCCESS_POST,
        responseSuccessPost
    } as const)
}

// Thunk

export type DispatchType = Dispatch<CurrentCvActionType>
export type ThunkType = ThunkAction<void, AppStateType, unknown, CurrentCvActionType>

export const setShortCv = (page: number, filter?: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.isNewCvAC(false))
        dispatch(actions.responseSuccessDeleteAC(false))
        dispatch(actions.responseSuccessAC(false))
        dispatch(actions.responseSuccessPostAC(false))
        dispatch(actions.isLoadingAvatarAC(false))
        dispatch(actions.clearCurrentCvAC())
        cvAPI.getListCv(page, filter)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.responseSuccessAC(true))
                        dispatch(actions.getShortCvAC(response.data.results))
                        dispatch(actions.totalCvsCountAC(response.data.count))
                        dispatch(actions.fetchPaginatorAC(false))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }

                }
            )
    }
}


export const setFilteredShortCv = (page: number, filter?: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.clearShortCvAC())
        dispatch(actions.offsetPaginatorAC(0))
        dispatch(actions.responseSuccessDeleteAC(false))
        dispatch(actions.responseSuccessAC(false))
        dispatch(actions.responseSuccessPostAC(false))
        dispatch(actions.clearCurrentCvAC())
        cvAPI.getListCv(0, filter)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.responseSuccessAC(true))
                        dispatch(actions.getShortCvAC(response.data.results))
                        dispatch(actions.totalCvsCountAC(response.data.count))
                        dispatch(actions.fetchPaginatorAC(false))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const setFilterCv = (filter: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.setFilterAC(filter))
        dispatch(actions.responseSuccessAC(false))
    }
}

export const setCurrentCv = (id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.isNewCvAC(false))
        dispatch(actions.isLoadingAvatarAC(false))
        dispatch(actions.clearCurrentCvAC())
        dispatch(actions.clearShortCvAC())
        dispatch(actions.offsetPaginatorAC(0))
        cvAPI.getCurrentCv(id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.responseSuccessPostAC(false))
                        dispatch(actions.getCurrentCvAC(response.data))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                })
    }
}

export const createNewCv = (newCv: CvType, id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.postedAvatarAC(''))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.responseSuccessDeleteAC(false))
        dispatch(actions.isLoadingAvatarAC(false))
        dispatch(actions.offsetPaginatorAC(0))
        dispatch(actions.responseSuccessPostAC(false))
        dispatch(actions.responseSuccessAC(false))
        dispatch(actions.clearCurrentCvAC())
        dispatch(actions.clearShortCvAC())
        cvAPI.updateCv(newCv, id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.getCurrentCvAC(response.data))
                        dispatch(actions.isNewCvAC(true))
                        dispatch(actions.fetchPaginatorAC(true))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const postCvForm = (editedCv: CvType, id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isSavingAC(true))
        dispatch(actions.postedAvatarAC(''))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.isNewCvAC(false))
        dispatch(actions.isLoadingAvatarAC(false))
        dispatch(actions.clearShortCvAC())
        cvAPI.updateCv(editedCv, id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.getCurrentCvAC(response.data))
                        dispatch(actions.responseSuccessPostAC(true))
                        dispatch(actions.isSavingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const postAvatarForm = (avatar: any, id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAvatarAC(true))
        dispatch(actions.postedAvatarAC(''))
        dispatch(actions.clearResponseErrorAC())
        cvAPI.postAvatar(avatar, id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.postedAvatarAC(response.data.url))
                        dispatch(actions.isLoadingAvatarAC(false))
                    } else {
                        dispatch(actions.postedAvatarAC(''))
                        dispatch(actions.isLoadingAvatarAC(false))
                    }

                }
            )
    }
}

export const deleteAvatarForm = (id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAvatarAC(true))
        dispatch(actions.clearResponseErrorAC())
        cvAPI.deleteAvatar(id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.postedAvatarAC(response.data.url))
                        dispatch(actions.isLoadingAvatarAC(false))
                    } else {
                        dispatch(actions.isLoadingAvatarAC(false))
                    }

                }
            )
    }
}

export const deleteCv = (id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.isNewCvAC(false))
        dispatch(actions.clearCurrentCvAC())
        dispatch(actions.clearShortCvAC())
        cvAPI.deleteCv(id)
            .then(response => {
                    if (response.status === 204) {
                        dispatch(actions.responseSuccessDeleteAC(true))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const getLinkPublicCvAction = (id: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        cvAPI.getPublicCv(id)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.getPublicCvAC(response.data.secret))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const showPublicCvAction = (id: string, secret: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingAC(true))
        dispatch(actions.clearResponseErrorAC())
        showPublicCv(id, secret)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.getCurrentCvAC(response.data))
                        dispatch(actions.isLoadingAC(false))
                    } else {
                        dispatch(actions.responseErrorAC(response))
                        dispatch(actions.isLoadingAC(false))
                    }
                }
            )
    }
}

export const clearCurrentCvAction = (): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.clearCurrentCvAC())
    }
}

export const clearShortCv = (): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.clearShortCvAC())
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.fetchPaginatorAC(true))
        dispatch(actions.offsetPaginatorAC(0))
    }
}

export const addFetchPaginator = (): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.fetchPaginatorAC(true))
    }
}

export const changeOffsetPaginator = (pageCount: number): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.offsetPaginatorAC(pageCount))
    }
}

export const clearSuccesses = (): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.responseSuccessPostAC(false))
        dispatch(actions.responseSuccessDeleteAC(false))
        dispatch(actions.isNewCvAC(false))
    }
}

export const getSkills = (skillsName: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingSkillsAC(true))
        dispatch(actions.clearResponseErrorAC())
        dispatch(actions.foundSkillsAC([]))
        cvAPI.getSkill(skillsName)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.foundSkillsAC(response.data.results.map((e: { label: string }) => e.label)))
                        dispatch(actions.isLoadingSkillsAC(false))
                    } else {
                        dispatch(actions.foundSkillsAC(['server error']))
                        dispatch(actions.isLoadingSkillsAC(false))
                    }
                }
            )
    }
}

export const getCvPdf= (id: string, name: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingPdfAC(true))
        dispatch(actions.pdfErrorAC(''))
        cvAPI.getPdf(id, name)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.isLoadingPdfAC(false))
                    } else {
                        dispatch(actions.pdfErrorAC(response.statusText))
                        dispatch(actions.isLoadingPdfAC(false))
                    }
                }
            )
    }
}

export const getPublicCvPdf= (id: string, name: string, secret: string): ThunkType => {
    return (dispatch: DispatchType) => {
        dispatch(actions.isLoadingPdfAC(true))
        dispatch(actions.pdfErrorAC(''))
        getPublicPdf(id, name, secret)
            .then(response => {
                    if (response.status === 200) {
                        dispatch(actions.isLoadingPdfAC(false))
                    } else {
                        dispatch(actions.pdfErrorAC(response.statusText))
                        dispatch(actions.isLoadingPdfAC(false))
                    }
                }
            )
    }
}