import React from "react";
import {Sidebar} from "../Sidebar";
import styles from "./Main.module.scss";
import {Routes} from "../../Routes/Routes";

export const Main = ({isLogin}: {isLogin: boolean}) => {

    return (
        <div className={styles.appWrapper}>
            {isLogin
                ? <aside>
                    <Sidebar/>
                </aside>
                : null}
            <Routes/>
        </div>
    )
}
